import React from "react";
import { Button } from "react-admin";
import PrintIcon from "@material-ui/icons/Print";
import { API_URL } from "../config";
import { getToken } from "../authHeaders";

const handlePrint = selectedIds => {
  const token = getToken();
  window.open(
    `${API_URL}/products/print.pdf?token=${token}&ids=${selectedIds.join(",")}`,
    "_blank"
  );
};
const PrintButton = ({ selectedIds, ...props }) => (
  <Button
    label={"resources.products.buttons.print"}
    onClick={handlePrint.bind(this, selectedIds)}
  >
    <PrintIcon />
  </Button>
);

export default PrintButton;
