import React from "react";
import { Edit } from "react-admin";
import Title from "../components/Title";
import Form from "./Form";

const StoreEdit = (props) => {
	return (
		<Edit {...props} title={<Title source="name" />} undoable={false}>
			<Form />
		</Edit>
	);
};
export default StoreEdit;
