import React from "react";
import { RefreshButton, TopToolbar } from "react-admin";
import LoadButton from "./LoadButton";
import UnloadButton from "./UnloadButton";
import UnloadMassiveButton from "./UnloadMassiveButton";

const Actions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter
}) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <RefreshButton />
    <LoadButton />
    <UnloadButton />
    <UnloadMassiveButton />
  </TopToolbar>
);

export default Actions;
