import carriers from "./carriers";
import carriersUsers from "./carriers-users";
import customers from "./customers";
import marketplaces from "./marketplaces";
import orders from "./orders";
import printTransactions from "./print-transactions";
import productItems from "./product-items";
import products from "./products";
import roles from "./roles";
import saleChannels from "./sale-channels";
import shippings from "./shippings";
import smsTemplates from "./sms-templates";
import statuses from "./statuses";
import stores from "./stores";
import tags from "./tags";
import users from "./users";

export default [
	{ name: "products", ...products },
	{ name: "print-transactions", ...printTransactions },
	{ name: "product-items", ...productItems },
	{ name: "stores", ...stores },
	{ name: "sale-channels", ...saleChannels },
	{ name: "orders", ...orders },
	{ name: "shippings", ...shippings },
	{ name: "customers", ...customers },
	{ name: "carriers", ...carriers },
	{ name: "carriers-users", ...carriersUsers },
	{ name: "marketplaces", ...marketplaces },
	{ name: "statuses", ...statuses },
	{ name: "sms-templates", ...smsTemplates },
	{ name: "tags", ...tags },
	{ name: "users", ...users },
	{ name: "roles", ...roles },
];
