import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { translate } from "react-admin";
import compose from "recompose/compose";
import CardIcon from "./CardIcon";

const styles = {
	main: {
		flex: "1",
		marginTop: 20,
		minHeight: 100,
	},
	card: {
		overflow: "inherit",
		textAlign: "right",
		padding: 16,
		minHeight: 76,
	},
};

const Indicator = ({ label, value, color, icon, translate, classes }) => (
	<div className={classes.main}>
		<CardIcon Icon={icon} bgColor={color} />
		<Card className={classes.card}>
			<Typography className={classes.title} color="textSecondary">
				{translate(label)}
			</Typography>
			<Typography variant="subtitle1" component="h2">
				{value}
			</Typography>
		</Card>
	</div>
);

const enhance = compose(withStyles(styles), translate);

export default enhance(Indicator);
