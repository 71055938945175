import React, { Fragment } from "react";
import { Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import calculateProfit from "../calculateProfit";
const styles = theme => ({
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});
const SaleChannelsField = ({ classes, source, record }) => (
  <Fragment>
    {(record[source] || [])
      .filter(sc => sc.sale_channel && sc.sale_channel !== null)
      .map(sc => (
        <Chip
          className={classes.chip}
          key={sc.id}
          label={`${sc.sale_channel.name} (Pv=${sc.price}, Un=${calculateProfit(
            record,
            sc
          ).toFixed(2)})`}
        />
      ))}
  </Fragment>
);

export default compose(withStyles(styles))(SaleChannelsField);
