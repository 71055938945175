import { ShoppingBasketIcon } from "../icons";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

export default {
  options: {
    group: "shipping",
    roles: ["admin", "shippings"],
  },
  icon: ShoppingBasketIcon,
  list: List,
  edit: Edit,
  create: Create,
};
