import React from "react";
import { translate } from "ra-core";
import { Link } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { compose } from "recompose";
import { API_URL } from "../config";
import { getToken } from "../authHeaders";

const PrintButton = ({ record: { id } }) => (
  <Link href={`${API_URL}/print-transactions/${id}.pdf?token=${getToken()}`}>
    <PrintIcon />
  </Link>
);

export default compose(translate)(PrintButton);
