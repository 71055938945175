import { makeStyles } from "@material-ui/core";
import React from "react";
import {
	BooleanField,
	ChipField,
	Datagrid,
	DateField,
	EditButton,
	List,
	NumberField,
	ReferenceField,
	SelectField,
	TextField,
} from "react-admin";
import ChipColorField from "../components/ChipColorField";
import Pagination from "../components/Pagination";
import perPage from "../utils/perPage";
import BulkActionButtons from "./BulkActionButtons";
import Filters from "./Filters";
import paymentMethods from "./payment-methods";

const useStyle = makeStyles({
	list: {
		"& table td, table th": {
			whiteSpace: "nowrap",
		},
	},
});

const OrderList = (props) => {
	const classes = useStyle();
	return (
		<React.Fragment>
			<List
				{...props}
				perPage={perPage()}
				filters={<Filters />}
				className={classes.list}
				sort={{ field: "modified", order: "DESC" }}
				bulkActionButtons={<BulkActionButtons />}
				pagination={<Pagination />}
			>
				<Datagrid rowClick="edit">
					<ChipField source="fullcode" style={{ fontWeight: "bold" }} />
					<ReferenceField source="status_id" reference="statuses" perPage={100}>
						<ChipColorField source="name" />
					</ReferenceField>
					<TextField source="store_status" />
					<ReferenceField source="customer_id" reference="customers">
						<ChipField source="fullname" />
					</ReferenceField>
					<SelectField source="payment_method" choices={paymentMethods} />
					<NumberField
						source="amount"
						options={{ style: "currency", currency: "EUR" }}
					/>
					<BooleanField source="has_notes" />
					<ReferenceField source="store_id" reference="stores">
						<ChipField source="name" />
					</ReferenceField>
					<DateField showTime source="modified" />
					<EditButton />
				</Datagrid>
			</List>
		</React.Fragment>
	);
};
export default OrderList;
