import { ProductItemIcon } from "../icons";
import List from "./List";
export default {
  icon: ProductItemIcon,
  options: {
    group: "manage",
    roles: ["admin", "stores"],
  },
  list: List,
};
