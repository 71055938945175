import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Login } from "react-admin";

const styles = theme =>
  createStyles({
    login: {
      backgroundColor: "#f9f9f9"
    }
  });

const CustomLoginPage = ({ classes }) => (
  <Login backgroundImage={null} className={classes.login} />
);

export default withStyles(styles)(CustomLoginPage);
