import LinearProgress from "@material-ui/core/LinearProgress";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import React, { useState } from "react";
import { Button, useNotify, useRedirect, useTranslate } from "react-admin";
import authProvider from "../authProvider";

const LoginButton = ({
	basePath = "",
	label = "ra.auth.sign_in",
	record,
	...rest
}) => {
	const [loading, setLoading] = useState(false);
	const redirect = useRedirect();
	const notify = useNotify();
	const translate = useTranslate();
	const handleClick = () => {
		setLoading(true);
		authProvider("subLogin", { sub_user_id: record.id })
			.then((success) => {
				setLoading(false);
				notify(translate("ra.auth.sign_in_success"), "success");
				redirect("/");
				setTimeout(() => window.location.reload(), 200);
				//refresh();
			})
			.catch((error) => {
				setLoading(false);
				notify(translate("ra.auth.sign_in_error"), "warning");
			});
	};

	if (loading) return <LinearProgress />;

	return (
		<Button color="" label={label} onClick={handleClick} {...rest}>
			<VpnKeyIcon />
		</Button>
	);
};

export default LoginButton;
