import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	Edit,
	FormTab,
	ReferenceInput,
	SelectInput,
	TabbedForm,
	TextInput,
	useTranslate,
} from "react-admin";
import { compose } from "recompose";
import Title from "../components/Title";
import CustomerForm from "../customers/Form";
import OrderItemForm from "../order-items/Form";
import OrderForm from "./Form";

const OrderEdit = ({ dispatch, validate, errors, ...props }) => {
	const translate = useTranslate();
	return (
		<Edit title={<Title source="fullcode" />} {...props} undoable={false}>
			<TabbedForm validate={validate}>
				<FormTab label={translate("resources.orders.name", 1)}>
					<OrderForm fullWidth />
					<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
				</FormTab>
				<FormTab label={translate("resources.customers.name", 1)}>
					<CustomerForm fullWidth source="customer." />
				</FormTab>
				<FormTab label={translate("resources.order_items.name", 0)}>
					<OrderItemForm fullWidth />
				</FormTab>
				<FormTab label={translate("resources.shippings.name", 1)}>
					<ReferenceInput source="carrier_user_id" reference="carriers-users">
						<SelectInput />
					</ReferenceInput>
					<TextInput
						label="resources.shippings.fields.tracking"
						source="shipping.tracking"
						disabled
					/>
					<TextInput
						label="resources.shippings.fields.status"
						source="shipping.status"
						disabled
					/>
					<TextInput
						label="resources.shippings.fields.tracking_url"
						source="tracking_url"
						fullWidth
					/>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
export default compose(withErrors)(OrderEdit);
