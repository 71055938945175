import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import LinearProgress from "@material-ui/core/LinearProgress";

const useBarStyles = makeStyles({
  rootGreen: {
    height: 10,
    backgroundColor: green[100]
  },
  rootRed: {
    height: 10,
    backgroundColor: red[100]
  },
  barGreen: {
    borderRadius: 0,
    backgroundColor: green[500]
  },
  barRed: {
    borderRadius: 0,
    backgroundColor: red[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  margin: {
    margin: theme.spacing(0)
  }
}));

const StockField = ({ source, record }) => {
  const classes = useStyles();
  const stock = record[source] || { loaded: 0, unloaded: 0 };
  const total = stock.loaded + stock.unloaded;
  const perc = (100 / total) * stock.loaded || 0;
  const barClasses = useBarStyles();

  const danger = stock.loaded < record.min_quantity;
  return (
    <Fragment>
      <LinearProgress
        className={classes.margin}
        variant="determinate"
        classes={{
          root: danger ? barClasses.rootRed : barClasses.rootGreen,
          bar: danger ? barClasses.barRed : barClasses.barGreen
        }}
        color="secondary"
        value={perc}
      />
      {stock.loaded}/{total} ({perc.toFixed(2)}%)
    </Fragment>
  );
};

export default StockField;
