import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import React, { Fragment, useState } from "react";
import {
	Button,
	useNotify,
	useQuery,
	useRefresh,
	useTranslate,
	useUnselectAll,
	useUpdateMany,
} from "react-admin";

const StatusButton = ({ selectedIds, resource }) => {
	const [open, setOpen] = useState(false);
	const [status, setStatus] = useState("");
	const translate = useTranslate();

	const handleClose = () => {
		setOpen(false);
	};

	const handleClick = () => {
		setOpen(true);
	};

	const handleChange = (e) => setStatus(e.target.value);

	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll();
	const [updateMany, { loading }] = useUpdateMany(
		resource,
		selectedIds,
		{ status_id: status },
		{
			onSuccess: () => {
				refresh();
				notify("resources.orders.status.updated");
				unselectAll("orders");
			},
			onFailure: (error) => notify("resources.orders.status.error", "warning"),
		}
	);

	const { data: statuses } = useQuery({
		type: "getList",
		resource: "statuses",
		payload: {
			pagination: { page: 1, limit: 200 },
			sort: { field: "id", direction: "ASC" },
		},
	});

	return (
		<Fragment>
			<Button label="resources.orders.fields.status_id" onClick={handleClick}>
				<InfoIcon />
			</Button>
			<Dialog
				fullWidth
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					{translate("resources.orders.status.update")}
				</DialogTitle>
				<DialogContent>
					<FormControl
						style={{ minWidth: 200 }}
						margin="dense"
						variant="filled"
						fullWidth
						required
					>
						<InputLabel id="status-bulk-actions-select-label">
							{translate("resources.orders.fields.status_id")}
						</InputLabel>
						<Select
							autoFocus
							labelId="status-bulk-actions-select-label"
							id="status-bulk-actions-select"
							value={status}
							onChange={handleChange}
						>
							<MenuItem value="">
								<em>{translate("ra.message.none")}</em>
							</MenuItem>
							{statuses &&
								statuses.map((status) => (
									<MenuItem key={status.id} value={status.id}>
										{status.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="primary"
						disabled={loading}
						label="ra.action.undo"
					/>
					<Button
						onClick={updateMany}
						disabled={loading || !status}
						color="primary"
						label="ra.action.edit"
						variant="contained"
					>
						<InfoIcon />
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

export default StatusButton;
