import { get } from "lodash";
import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	Create,
	FileField,
	FileInput,
	FormDataConsumer,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";

const OrderCreate = ({ dispatch, validate, errors, permissions, ...props }) => {
	return (
		<Create {...props}>
			<SimpleForm redirect="edit" validate={validate}>
				<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
				<ReferenceInput
					source="store_id"
					reference="stores"
					validate={[required()]}
				>
					<SelectInput />
				</ReferenceInput>
				<SelectInput
					source="method"
					choices={[
						{ id: "json", name: "resources.orders.fields.method.json" },
						{ id: "csv", name: "resources.orders.fields.method.csv" },
					]}
				>
					<TextInput />
				</SelectInput>
				<FormDataConsumer>
					{({ formData, ...rest }) => {
						let method = get(formData, "method");
						switch (method) {
							case "json":
								return (
									<TextInput
										{...rest}
										className={{}}
										multiline
										rows={30}
										source="r_data"
										validate={[required()]}
										fullWidth
										label="resources.orders.fields.method.json"
									/>
								);
							case "csv":
								return (
									<FileInput
										{...rest}
										accept=".csv"
										className={{}}
										multiline
										source="r_csv"
										validate={[required()]}
										fullWidth
										label="resources.orders.fields.method.csv"
									>
										<FileField source="src" title="title" />
									</FileInput>
								);
							default:
								return null;
						}
					}}
				</FormDataConsumer>
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(OrderCreate);
