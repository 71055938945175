import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	Edit,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";
import TextMessageInput from "../components/TextMessageInput";
import Title from "../components/Title";

const SmsTemplateEdit = ({ dispatch, validate, errors, ...props }) => (
	<Edit title={<Title source="name" />} {...props} undoable={false}>
		<SimpleForm validate={validate}>
			<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
			<TextInput source="code" validate={[required()]} />
			<TextInput source="name" validate={[required()]} />
			<ReferenceInput
				label="Status"
				source="status_id"
				reference="statuses"
				perPage={100}
				allowEmpty
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
			<TextMessageInput
				validate={required()}
				source="message"
				multiline
				fullWidth
				rowsMax="8"
				rows="5"
			/>
			<TextInput
				source="delay"
				helperText="+7 minutes, +1 day, + 2days, +1 month, ecc..."
			/>
		</SimpleForm>
	</Edit>
);
export default compose(withErrors)(SmsTemplateEdit);
