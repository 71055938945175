import React from "react";
import PropTypes from "prop-types";
import { TextField, withStyles, createStyles } from "@material-ui/core";
import compose from "recompose/compose";
import { translate } from "ra-core";

const styles = (theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      padding: theme.spacing(1),
      paddingLeft: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: 230,
    },
    formControl: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  });
class Toolbar extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);

    this.state = {
      from: this.props.from || "",
      to: this.props.to || "",
    };
  }

  handleChange(filterName, evt) {
    const { onChange } = this.props;
    this.setState(
      {
        [filterName]: evt.target.value,
      },
      () => onChange(this.state)
    );
  }

  render() {
    const { classes, translate } = this.props;
    return (
      <div className={classes.container}>
        <TextField
          type="datetime-local"
          className={classes.textField}
          label={translate("dashboard.filters.from")}
          value={this.state.from}
          onChange={this.handleChange.bind(this, "from")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          type="datetime-local"
          className={classes.textField}
          label={translate("dashboard.filters.to")}
          value={this.state.to}
          onChange={this.handleChange.bind(this, "to")}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    );
  }
}

export default compose(translate, withStyles(styles))(Toolbar);
