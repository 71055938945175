import React from "react";
import {
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput
} from "react-admin";
import { compose } from "recompose";

const Filters = props => (
  <Filter {...props}>
    <TextInput source="barcode" resettable alwaysOn />
    <ReferenceInput
      source="product_id"
      reference="products"
      label="resources.product-items.fields.product.name"
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="print_transaction_id"
      reference="print-transactions"
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      source="status"
      choices={[
        {
          id: "loaded",
          name: "resources.product-items.status.loaded"
        },
        {
          id: "unloaded",
          name: "resources.product-items.status.unloaded"
        }
      ]}
    />
  </Filter>
);
export default compose()(Filters);
