import React from "react";
import { Datagrid, DateField, EditButton, List, TextField } from "react-admin";

const SaleChannelList = (props) => (
	<List {...props} perPage={10}>
		<Datagrid>
			<TextField source="id" />
			<TextField source="name" style={{ fontWeight: "bold" }} />
			<DateField source="created" showTime />
			<DateField source="modified" showTime />
			<EditButton />
		</Datagrid>
	</List>
);
export default SaleChannelList;
