import React from "react";
import { Create, SimpleForm, TextInput, required } from "react-admin";

const SaleChannelCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
);
export default SaleChannelCreate;
