import SendIcon from "@material-ui/icons/Send";
import React, { Fragment, useState } from "react";
import {
	Button,
	Confirm,
	useMutation,
	useNotify,
	useRefresh,
	useUnselectAll,
} from "react-admin";
import { GENERIC } from "../dataProvider";

const TransmitButton = ({ translate, resource, selectedIds }) => {
	const [open, setOpen] = useState(false);
	const refresh = useRefresh();
	const unselectAll = useUnselectAll();
	const notify = useNotify();
	const [transmit, { loading }] = useMutation(
		{
			type: GENERIC,
			resource: "shippings/transmit",
			payload: {
				method: "POST",
				data: {
					ids: selectedIds,
				},
			},
		},
		{
			onSuccess: () => {
				refresh();
				notify("resources.shippings.transmit.success");
				unselectAll("shippings");
				setTimeout(() => setOpen(false), 500);
			},
			onFailure: (error) =>
				notify("resources.shippings.transmit.error", "warning"),
		}
	);

	return (
		<Fragment>
			<Button
				onClick={() => setOpen(true)}
				label="resources.shippings.transmit_shipping"
			>
				<SendIcon />
			</Button>
			<Confirm
				isOpen={open}
				title="resources.shippings.transmit_shipping"
				content="resources.shippings.transmit_shipping_info"
				onConfirm={transmit}
				onClose={() => setOpen(false)}
				loading={loading}
			/>
		</Fragment>
	);
};

export default TransmitButton;
