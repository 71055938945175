import ImportExportIcon from "@material-ui/icons/ImportExport";
import React, { Fragment } from "react";
import { Button, useRefresh } from "react-admin";
import { EXPORT_URL } from "../config";

const ExportButton = ({ storeId, selectedIds }) => {
	const authToken = localStorage.getItem("token");
	const ids = selectedIds.join(",");
	const refresh = useRefresh();
	return (
		<Fragment>
			<Button
				onClick={() => setTimeout(() => refresh("shippings"), 2000)}
				href={`${EXPORT_URL}?ids=${ids}&store_id=${storeId}&token=${authToken}`}
				target="_blank"
				rel="noopener noreferrer"
				label="resources.shippings.export"
			>
				<ImportExportIcon />
			</Button>
		</Fragment>
	);
};

export default ExportButton;
