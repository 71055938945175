import { Chip } from "@material-ui/core";
import React from "react";
import { translate } from "react-admin";
import { compose } from "recompose";
import statuses from "./statuses";

const StatusField = ({ component = "p", translate, source, record }) => {
	let status = statuses.find((s) => s.id === record[source]);
	return (
		<Chip
			style={{
				backgroundColor: status.color,
				color: "white",
			}}
			component={component}
			icon={<status.Icon style={{ color: "white" }} />}
			label={translate(`resources.shippings.fields.statuses.${record[source]}`)}
		/>
	);
};

export default compose(translate)(StatusField);
