import { get } from "lodash";
import React from "react";
import { BulkDeleteButton } from "react-admin";
import ExportButton from "./ExportButton";
import PrintLabelButton from "./PrintLabelButton";
import TransmitButton from "./TransmitButton";

const ShippingBulkActionButtons = (props) => {
	const { filterValues } = props;
	const storeId = get(filterValues, "store_id");
	return (
		<React.Fragment>
			{storeId && <ExportButton storeId={storeId} {...props} />}
			<PrintLabelButton {...props} />
			<TransmitButton {...props} />
			<BulkDeleteButton {...props} />
		</React.Fragment>
	);
};

export default ShippingBulkActionButtons;
