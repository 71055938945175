// in ./StatusButton.js
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import SendIcon from "@material-ui/icons/Send";
import React, { Fragment, useState } from "react";
import { Button, useMutation, useQuery, useTranslate } from "react-admin";
import { GENERIC } from "../dataProvider";

const SmsButton = ({
	basePath,
	crudUpdateMany,
	resource,
	selectedIds,
	...props
}) => {
	const [open, setOpen] = useState(false);
	const [smsTemplate, setSmsTemplate] = useState("");

	const handleClose = () => {
		setOpen(false);
	};

	const handleClick = () => {
		setOpen(true);
	};

	const handleChange = (e) => setSmsTemplate(e.target.value);
	const translate = useTranslate();

	const { data: smsTemplates } = useQuery({
		type: "getList",
		resource: "sms-templates",
		payload: {
			pagination: { page: 1, limit: 200 },
			sort: { field: "name", direction: "ASC" },
		},
	});

	const [sendSms, { loading }] = useMutation(
		{
			type: GENERIC,
			resource: "orders/sms",
			payload: {
				method: "POST",
				data: {
					ids: selectedIds.join(","),
					sms_template_id: smsTemplate.id,
				},
			},
		},
		{
			onSuccess: (response) => {
				setOpen(false);
			},
		}
	);

	return (
		<Fragment>
			<Button label="resources.orders.send_sms" onClick={handleClick}>
				<SendIcon />
			</Button>
			<Dialog
				fullWidth
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					{translate("resources.orders.send_sms")}
				</DialogTitle>
				<DialogContent>
					<FormControl
						style={{ minWidth: 200 }}
						margin="dense"
						variant="filled"
						fullWidth
						required
					>
						<InputLabel id="sms-templates-bulk-actions-select-label">
							{translate("resources.sms-templates.name")}
						</InputLabel>
						<Select
							autoFocus
							labelId="sms-templates-bulk-actions-select-label"
							id="sms-templates-bulk-actions-select"
							value={smsTemplate}
							onChange={handleChange}
						>
							<MenuItem value="">
								<em>{translate("ra.message.none")}</em>
							</MenuItem>
							{smsTemplates &&
								smsTemplates.map((smsTemplate) => (
									<MenuItem key={smsTemplate.id} value={smsTemplate}>
										{smsTemplate.name}
									</MenuItem>
								))}
						</Select>
						{smsTemplate && (
							<div style={{ fontFamily: "Roboto", marginTop: 10 }}>
								<Typography variant="h5" gutterBottom>
									{smsTemplate.name}
								</Typography>
								<Typography
									variant="body1"
									style={{ whiteSpace: "pre-line", marginTop: 10 }}
									gutterBottom
								>
									{smsTemplate.message}
								</Typography>
							</div>
						)}
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="primary"
						disabled={loading}
						label="ra.action.undo"
					/>
					<Button
						onClick={sendSms}
						disabled={loading || !smsTemplate}
						color="primary"
						label="ra.action.send"
						variant="contained"
					>
						<SendIcon />
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

export default SmsButton;
