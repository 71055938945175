import { Grid, LinearProgress, Paper } from "@material-ui/core";
import EuroIcon from "@material-ui/icons/Euro";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import moment from "moment";
import { translate, WithPermissions } from "ra-core";
import React, { Component } from "react";
import { Title, withDataProvider } from "react-admin";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import compose from "recompose/compose";
import { GET_STATS } from "../addDashboardFeature";
import { OrderIcon, ProductItemIcon, ShippingIcon } from "../icons";
import Indicator from "./Indicator";
import List from "./List";
import Table from "./Table";
import Toolbar from "./Toolbar";

const TotalOrdersIndicator = ({ getValue, ...props }) => (
	<Indicator
		color="#f44336"
		label="dashboard.total_orders"
		value={getValue("total_orders")}
		icon={OrderIcon}
	/>
);
const TotalShippingsIndicator = ({ getValue, ...props }) => (
	<Indicator
		color="#ffd54f"
		label="dashboard.total_shippings"
		value={getValue("total_shippings")}
		icon={ShippingIcon}
	/>
);
const TotalLoadedProductItemsIndicator = ({ getValue, ...props }) => (
	<Indicator
		color="#607d8b"
		label="dashboard.total_loaded_product_items"
		value={getValue("total_loaded_product_items")}
		icon={ProductItemIcon}
	/>
);

const TotalUnloadedProductItemsIndicator = ({ getValue, ...props }) => (
	<Indicator
		color="#607d8b"
		label="dashboard.total_unloaded_product_items"
		value={getValue("total_unloaded_product_items")}
		icon={ProductItemIcon}
	/>
);

const TotalPendingOrdersIndicator = ({ getValue }) => (
	<Indicator
		color="#f1c40f"
		label="dashboard.total_pending_orders"
		value={getValue("total_pending_orders")}
		icon={OrderIcon}
	/>
);

const TotalCodDeliveredOrdersIndicator = ({ getValue }) => (
	<Indicator
		color="#2980b9"
		label="dashboard.total_cod_delivered_orders"
		value={getValue("total_cod_delivered_orders")}
		icon={LocalShippingIcon}
	/>
);

const TotalCodDeliveredOrdersAmountIndicator = ({ getValue }) => (
	<Indicator
		color="#27ae60"
		label="dashboard.total_cod_delivered_orders_amount"
		value={getValue("total_cod_delivered_orders_amount")}
		icon={EuroIcon}
	/>
);
const TotalCodRejectedOrdersIndicator = ({ getValue }) => (
	<Indicator
		color="#e74c3c"
		label="dashboard.total_cod_rejected_orders"
		value={getValue("total_cod_rejected_orders")}
		icon={MoodBadIcon}
	/>
);
const TotalPrepaidDeliveredOrdersIndicator = ({ getValue }) => (
	<Indicator
		color="#2980b9"
		label="dashboard.total_prepaid_delivered_orders"
		value={getValue("total_prepaid_delivered_orders")}
		icon={OrderIcon}
	/>
);

const TotalPrepaidDeliveredOrdersAmountIndicator = ({ getValue }) => (
	<Indicator
		color="#27ae60"
		label="dashboard.total_prepaid_delivered_orders_amount"
		value={getValue("total_prepaid_delivered_orders_amount")}
		icon={EuroIcon}
	/>
);

const TotalRejectedOrdersPercIndicator = ({ getValue }) => (
	<Indicator
		color="#d35400"
		label="dashboard.total_rejected_orders_perc"
		value={getValue("total_rejected_orders_perc")}
		icon={MoodBadIcon}
	/>
);

const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";

class Dashboard extends Component {
	state = {
		stats: {
			indicators: [],
			tables: [],
		},
		logs: [],
		from: null,
		to: null,
	};
	connection = null;
	redirect = null;

	constructor(props) {
		super(props);
		this.getValue = this.getValue.bind(this);
		this.state.from = moment().startOf("day").format(DATE_FORMAT);
		this.state.to = moment().endOf("day").format(DATE_FORMAT);
	}

	componentDidMount() {
		this.redirect = localStorage.getItem("redirect");
		if (this.redirect) {
			localStorage.removeItem("redirect");
		} else {
			const { from, to } = this.state;
			this.fetchData(from, to);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.redirect) {
			return;
		}
		// handle refresh
		if (this.props.version !== prevProps.version) {
			const { from, to } = this.state;
			this.fetchData(from, to);
		}
	}

	async fetchData(from, to) {
		const { dataProvider } = this.props;
		const {
			data: { data },
		} = await dataProvider(GET_STATS, "dashboard", { from, to });

		this.setState({
			stats: data,
		});
	}

	handleChange({ from, to }) {
		this.setState({ from, to }, () => this.fetchData(from, to));
	}

	getValue(name) {
		if (this.state.stats && this.state.stats.indicators) {
			let v = this.state.stats.indicators.find((i) => i.name === name);
			let r = v !== undefined ? v.value : 0;
			return r;
		}
		return 0;
	}

	render() {
		if (this.redirect) {
			return <Redirect to={this.redirect} />;
		}
		const { getValue } = this;

		return (
			<div>
				<Title title={"menu.items.dashboard"} />
				<Grid container spacing={2}>
					<Grid item lg={12}>
						<Paper elevation={1}>
							<Toolbar
								from={this.state.from}
								to={this.state.to}
								onChange={this.handleChange.bind(this)}
							/>
						</Paper>
					</Grid>
					<Grid item lg={3} md={6} xs={12}>
						<TotalOrdersIndicator getValue={getValue} />
					</Grid>
					<Grid item lg={3} md={6} xs={12}>
						<TotalShippingsIndicator getValue={getValue} />
					</Grid>
					<Grid item lg={3} md={6} xs={12}>
						<TotalLoadedProductItemsIndicator getValue={getValue} />
					</Grid>
					<Grid item lg={3} md={6} xs={12}>
						<TotalUnloadedProductItemsIndicator getValue={getValue} />
					</Grid>
					<Grid item lg={4} xs={12}>
						<TotalPendingOrdersIndicator getValue={getValue} />
					</Grid>
					<Grid item lg={4} xs={12}>
						<TotalCodDeliveredOrdersIndicator getValue={getValue} />
					</Grid>
					<Grid item lg={4} xs={12}>
						<TotalCodDeliveredOrdersAmountIndicator getValue={getValue} />
					</Grid>
					{this.state.stats && this.state.stats.tables ? (
						this.state.stats.tables.map((table, index) => (
							<Grid item lg={6} xs={12} key={`table-${index}`}>
								{table.type === "list" && <List {...table} />}
								{table.type === "table" && <Table {...table} />}
							</Grid>
						))
					) : (
						<LinearProgress />
					)}
					<Grid item lg={6} md={6} xs={12}>
						<TotalCodRejectedOrdersIndicator getValue={getValue} />
					</Grid>
					<Grid item lg={6} md={6} xs={12}>
						<TotalPrepaidDeliveredOrdersIndicator getValue={getValue} />
					</Grid>
					<Grid item lg={6} md={6} xs={12}>
						<TotalPrepaidDeliveredOrdersAmountIndicator getValue={getValue} />
					</Grid>
					<Grid item lg={6} md={6} xs={12}>
						<TotalRejectedOrdersPercIndicator getValue={getValue} />
					</Grid>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	version: state.admin.ui.viewVersion,
});

const ComposedDashboard = compose(
	connect(mapStateToProps),
	withDataProvider,
	translate
)(Dashboard);

const DashboardWithPermissions = (props) => (
	<WithPermissions
		render={({ permissions }) => (
			<ComposedDashboard permissions={permissions} />
		)}
	/>
);
export default DashboardWithPermissions;
