import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  NumberField
} from "react-admin";
import Filters from "./Filters";
import StockField from "./fields/StockField";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SaleChannelsField from "./fields/SaleChannelsField";
import PriceField from "./fields/PriceField";
import exporter from "./exporter";
import ProductBulkActions from "./BulkActions";

const ProductList = props => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <React.Fragment>
      <List
        {...props}
        perPage={10}
        filters={<Filters />}
        exporter={exporter}
        bulkActionButtons={<ProductBulkActions />}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          {isDesktop && <TextField source="store.name" />}
          <TextField source="code" />
          <TextField source="name" />
          <PriceField label={"resources.products.fields.prices"} />
          <NumberField
            source="final_price"
            options={{ style: "currency", currency: "EUR" }}
          />
          <StockField source="stock" />
          <SaleChannelsField source="sale_channels" />
          <DateField source="modified" showTime />
          <EditButton />
        </Datagrid>
      </List>
    </React.Fragment>
  );
};
export default ProductList;
