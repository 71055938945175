import React from "react";
import { Create } from "react-admin";
import Form from "./Form";

const StoreCreate = (props) => (
	<Create {...props}>
		<Form />
	</Create>
);
export default StoreCreate;
