import React from "react";
import { useFormState } from "react-final-form";

import { NumberInput, useInput } from "react-admin";
import calculateProfit from "../calculateProfit";

const ProfitField = ({ record, ...props }) => {
  const formState = useFormState({
    subscription: { values: true }
  });
  const {
    input: { value }
  } = useInput({ record, ...props });
  const product = formState.values;
  return (
    <NumberInput
      value={calculateProfit(product, value).toFixed(2)}
      label="resources.products.fields.profit"
      style={{ marginLeft: 8 }}
      disabled
    />
  );
};
export default ProfitField;
