import React, { Fragment } from "react";
import { translate, withDataProvider } from "ra-core";
import { Button as RaButton, Mutation } from "react-admin";
import { compose } from "recompose";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { RESOLVE, UNLOAD } from "../addProductFeature";
import beep from "../utils/beep";
import BarcodeTable from "./Unload/BarcodeTable";

const ConfirmButton = compose(translate)(
  ({ translate, payload, disabled, onSuccess, ...props }) => {
    const options = {
      undoable: false,
      onSuccess,
      onError: {
        notification: {
          body: "resources.product-items.messages.unload_fails",
          level: "warning"
        }
      }
    };
    return (
      <Mutation
        type={UNLOAD}
        resource="product-items"
        payload={payload}
        options={options}
      >
        {unload => (
          <Button disabled={disabled} color="primary" onClick={unload}>
            {translate("ra.action.confirm")}
          </Button>
        )}
      </Mutation>
    );
  }
);
class UnloadButton extends React.Component {
  state = {
    found: true,
    error: "",
    isOpen: false,
    processSeq: true,
    barcode: "",
    loading: false,
    data: []
  };
  handleClick = () => this.setState({ isOpen: true });
  handleDialogClose = () => this.setState({ isOpen: false });
  handleProcessSeqChange = e => this.setState({ processSeq: e.target.checked });
  handleBarcode = e => {
    if (e.keyCode !== 13) {
      return;
    }

    const { translate } = this.props;
    const { processSeq, data } = this.state;
    /**
     * ### LEGGIMI ###
     *
     * L'inventario utilizza la codifica EAN 13 quindi:
     * - Il primo digit viene scartato e quindi devo aggiungerlo, a noi è sempre 0.
     * - L'ultimo digit è un checksum e quindi va rimosso.
     */
    const barcode =
      "0" +
      (e.target.value.length > 11
        ? e.target.value.substr(0, 11)
        : e.target.value);
    let productItem = data.find(p => p.barcode === barcode);
    if (productItem) {
      let newData = [productItem].concat(data.filter(d => d !== productItem));
      this.setState(
        {
          data: newData,
          barcode: "",
          error: translate(
            "resources.product-items.messages.unload_errors.barcode_already_added"
          )
        },
        () => {
          beep(2) && this.refs.barcodeInput.focus();
        }
      );
      return;
    }
    if (!processSeq) {
      const { dataProvider } = this.props;

      dataProvider(RESOLVE, "product-items", { barcode }).then(
        ({ data: { found, unloaded, item } }) => {
          let beeps = 0;
          if (!found || unloaded) {
            beeps = 2;
          } else {
            beeps = 1;
          }
          this.setState(
            {
              barcode: "",
              loading: false,
              error: !found
                ? translate(
                    "resources.product-items.messages.unload_errors.barcode_not_found"
                  )
                : unloaded
                ? translate(
                    "resources.product-items.messages.unload_errors.barcode_already_unloaded"
                  )
                : null,
              data: found && !unloaded ? [item].concat(data) : data
            },
            () => {
              beep(beeps) && this.refs.barcodeInput.focus();
            }
          );
        }
      );
    } else {
      const { dataProvider } = this.props;
      dataProvider(UNLOAD, "product-items", {
        items: [{ barcode }]
      }).then(({ data: { process } }) => {
        beep(1);
        this.setState(
          { data: process.concat(this.state.data), barcode: "" },
          () => this.refs.barcodeInput.focus()
        );
      });
    }
  };
  handleBarcodeChange = e => this.setState({ barcode: e.target.value });

  handleConfirm = ({ data: { process } }) => this.setState({ data: process });

  handleTableClear = () => this.setState({ data: [] });

  render() {
    const { translate } = this.props;
    return (
      <Fragment>
        <RaButton
          onClick={this.handleClick}
          label={"resources.product-items.buttons.unload"}
        >
          <LaunchIcon />
        </RaButton>
        <Dialog
          maxWidth="md"
          fullWidth
          open={this.state.isOpen}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>
            {translate("resources.product-items.titles.unload")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {translate("resources.product-items.messages.unload")}
            </DialogContentText>
            <TextField
              ref="barcodeInput"
              key="barcodeInput"
              fullWidth
              autoFocus
              helperText={this.state.error}
              error={this.state.error !== null && this.state.error.length > 0}
              disabled={this.state.loading}
              label={translate("resources.product-items.fields.barcode")}
              type="text"
              value={this.state.barcode}
              onKeyDown={this.handleBarcode}
              onChange={this.handleBarcodeChange}
            />
            <FormControl>
              <FormControlLabel
                label={translate("resources.product-items.fields.process_seq")}
                control={
                  <Checkbox
                    checked={this.state.processSeq}
                    onChange={this.handleProcessSeqChange}
                  />
                }
              />
              <FormHelperText style={{ marginTop: -4 }}>
                {translate("resources.product-items.fields.process_seq_help")}
              </FormHelperText>
            </FormControl>

            <BarcodeTable rows={this.state.data} />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.handleDialogClose}>
              {translate("ra.action.close")}
            </Button>
            <Button
              disabled={this.state.data.length === 0}
              color="secondary"
              onClick={this.handleTableClear}
            >
              {translate("app.action.clear")}
            </Button>
            <ConfirmButton
              disabled={
                this.state.data.filter(d => d.error === undefined).length === 0
              }
              onSuccess={this.handleConfirm}
              payload={{
                items: this.state.data
              }}
            />
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default compose(translate, withDataProvider)(UnloadButton);
