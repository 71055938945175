import React from "react";
import { BulkDeleteButton } from "react-admin";
import AssignCarrierProfileButton from "./AssignCarrierProfileButton";
import CreateLabelButton from "./CreateLabelButton";
import DeleteLabelButton from "./DeleteLabelButton";
import PrintButton from "./PrintButton";
import SmsButton from "./SmsButton";
import StatusButton from "./StatusButton";

const OrderBulkActionButtons = (props) => (
	<React.Fragment>
		<PrintButton {...props} />
		<StatusButton {...props} />
		<AssignCarrierProfileButton {...props} />
		<CreateLabelButton {...props} />
		<DeleteLabelButton {...props} />
		<SmsButton {...props} />
		<BulkDeleteButton {...props} />
	</React.Fragment>
);

export default OrderBulkActionButtons;
