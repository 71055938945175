import React from "react";
import { BooleanInput, maxLength, SelectInput, TextInput } from "react-admin";
import dongles from "../../dongles";

const SmsInput = ({ ...props }) => {
	return (
		<React.Fragment>
			<BooleanInput source="send_sms" {...props} className={{}} />
			<SelectInput
				source="sms_dongle"
				choices={dongles}
				{...props}
				fullWidth
				className={{}}
			/>
			<TextInput
				source="sms_sender"
				{...props}
				validate={maxLength(11)}
				className={{}}
			/>
		</React.Fragment>
	);
};

export default SmsInput;
