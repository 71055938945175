export const messageHelper = [
	"{code}",
	"{amount}",
	"{amount_order}",
	"{customer.fullname}",
	"{customer.address}",
	"{customer.city}",
	"{customer.zip_code}",
	"{customer.phone}",
	"{customer_email}",
].join(", ");
