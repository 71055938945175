import LinearProgress from "@material-ui/core/LinearProgress";
import React from "react";
import {
	ArrayInput,
	FormDataConsumer,
	required,
	SimpleFormIterator,
	TextInput,
	useQuery,
	useTranslate,
} from "react-admin";
import { GENERIC } from "../dataProvider";

const CarriersUsersPropsInput = ({ source, carrierId, ...rest }) => {
	const translate = useTranslate();
	const { data, loading } = useQuery({
		type: GENERIC,
		resource: `carriers/get-arguments/${carrierId}`,
		payload: carrierId,
	});
	if (loading) return <LinearProgress />;
	return (
		<ArrayInput
			source={source}
			label="resources.carriers_users_props.name"
			defaultValue={data}
		>
			<SimpleFormIterator disableAdd disableRemove>
				<FormDataConsumer>
					{({ formData, scopedFormData, getSource, ...rest }) => {
						if (!scopedFormData.key) {
							return null;
						}
						return (
							<React.Fragment>
								<TextInput
									source={getSource("key")}
									disabled
									label="resources.carriers_users_props.fields.key"
									style={{ display: "none" }}
									fullWidth
								/>
								<TextInput
									source={getSource("value")}
									label={translate(
										`resources.carriers_users_props.fields.${scopedFormData.key}`
									)}
									validate={required()}
									fullWidth
								/>
							</React.Fragment>
						);
					}}
				</FormDataConsumer>
			</SimpleFormIterator>
		</ArrayInput>
	);
};

export default CarriersUsersPropsInput;
