import React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";
import { Chip } from "@material-ui/core";
import { translate } from "ra-core";
import Filters from "./Filters";
import Actions from "./Actions";
import BulkActions from "./BulkActions";
import { compose } from "recompose";
import { red, green } from "@material-ui/core/colors";

const CodeField = ({ source, record }) => <pre>{record[source]}</pre>;
const StatusField = compose(translate)(
  ({ source, record, translate, resource }) => (
    <Chip
      color="secondary"
      style={{
        backgroundColor: record[source] === "loaded" ? green[500] : red[500]
      }}
      label={translate(`resources.${resource}.status.${record[source]}`)}
    />
  )
);

const ProductItemList = props => (
  <List
    {...props}
    filters={<Filters />}
    sort={{ field: "id", order: "DESC" }}
    bulkActionButtons={<BulkActions />}
    actions={<Actions />}
  >
    <Datagrid>
      <TextField source="id" />
      <CodeField source="barcode" />
      <TextField source="product.name" />
      <StatusField source="status" showTime />
      <DateField source="created" showTime />
      <DateField source="modified" showTime />
    </Datagrid>
  </List>
);

export default ProductItemList;
