import { makeStyles } from "@material-ui/core";
import React from "react";
import {
	BooleanField,
	ChipField,
	Datagrid,
	DateField,
	EditButton,
	List,
	ReferenceField,
	TextField,
	useTranslate,
} from "react-admin";
import Pagination from "../components/Pagination";
import perPage from "../utils/perPage";
import BulkActionButtons from "./BulkActionButtons";
import Filters from "./Filters";
import StatusField from "./StatusField";

const useStyle = makeStyles({
	list: {
		"& table td, table th": {
			whiteSpace: "nowrap",
		},
	},
});

const ShippingList = (props) => {
	const classes = useStyle();
	const translate = useTranslate();
	return (
		<React.Fragment>
			<List
				{...props}
				perPage={perPage()}
				filters={<Filters />}
				className={classes.list}
				sort={{ field: "created", order: "DESC" }}
				bulkActionButtons={<BulkActionButtons />}
				exporter={false}
				pagination={<Pagination />}
			>
				<Datagrid rowClick="edit">
					<TextField source="tracking" style={{ fontWeight: "bold" }} />
					<ReferenceField source="carrier_user_id" reference="carriers-users">
						<ChipField source="name" />
					</ReferenceField>
					<ReferenceField source="order_id" reference="orders">
						<TextField source="code" />
					</ReferenceField>
					<ReferenceField
						allowEmpty
						label={translate("resources.customers.name", 1)}
						source="order_id"
						reference="orders"
					>
						<TextField source="customer.fullname" />
					</ReferenceField>
					<StatusField source="status" />
					<BooleanField source="label_printed" />
					<TextField source="last_track_row" />
					<DateField showTime source="created" />
					<DateField showTime source="modified" />
					<EditButton />
				</Datagrid>
			</List>
		</React.Fragment>
	);
};
export default ShippingList;
