import LinearProgress from "@material-ui/core/LinearProgress";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import { get } from "lodash";
import React from "react";
import {
	MenuItemLink,
	useQuery,
	useRedirect,
	UserMenu as RaUserMenu,
	useTranslate,
} from "react-admin";
import authProvider from "./authProvider";

const useStyles = makeStyles(
	(theme) => ({
		root: {
			color: theme.palette.text.secondary,
		},
		icon: { minWidth: theme.spacing(5) },
	}),
	{ name: "RaMenuItemLink" }
);

const UserMenu = (props) => {
	const translate = useTranslate();
	const redirect = useRedirect();
	const classes = useStyles();
	const { data, loading, error } = useQuery({
		type: "getOne",
		resource: "users/profile",
		payload: {
			id: "useless",
		},
	});
	if (loading) return <LinearProgress />;
	if (error) return <ErrorIcon />;
	if (!data) return null;
	const subToken = localStorage.getItem("sub_token");
	const handleSubLogout = (e) => {
		e.preventDefault();
		e.stopPropagation();
		authProvider("subLogout").then((success) => {
			redirect("/users");
			setTimeout(() => window.location.reload(), 200);
		});
	};
	return (
		<RaUserMenu label={get(data, "username")} {...props}>
			<MenuItemLink
				to={`/users/${get(data, "id", false)}`}
				primaryText={translate("resources.users.fields.profile.title")}
				leftIcon={<SettingsIcon />}
			/>
			{subToken && (
				<MenuItem className={classes.root}>
					<ListItemIcon className={classes.icon} onClick={handleSubLogout}>
						<ExitToAppIcon />
					</ListItemIcon>
					<span onClick={handleSubLogout}>
						{translate("ra.auth.sub_sign_out")}
					</span>
				</MenuItem>
			)}
		</RaUserMenu>
	);
};

export default UserMenu;
