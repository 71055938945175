import RemoteErrorsInteceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import { Create, SimpleForm } from "react-admin";
import { compose } from "recompose";
import CustomerForm from "./Form";
const CustomerCreate = ({ dispatch, validate, errors, ...props }) => {
	return (
		<Create {...props}>
			<SimpleForm redirect="list" validate={validate}>
				<RemoteErrorsInteceptor errors={errors} dispatch={dispatch} />
				<CustomerForm fullWidth />
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(CustomerCreate);
