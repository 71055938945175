import React from "react";
import { Datagrid, EditButton, List, TextField } from "react-admin";
import perPage from "../utils/perPage";
import Filters from "./Filters";

const MarketplaceList = (props) => (
	<React.Fragment>
		<List {...props} perPage={perPage()} filters={<Filters />}>
			<Datagrid rowClick="edit">
				<TextField source="id" />
				<TextField source="name" style={{ fontWeight: "bold" }} />
				<EditButton />
			</Datagrid>
		</List>
	</React.Fragment>
);
export default MarketplaceList;
