import React from "react";
import {
	AutocompleteInput,
	Filter,
	ReferenceInput,
	SelectInput,
	TextInput,
} from "react-admin";
const CarriersUserFilters = ({ permissions, ...props }) => (
	<Filter {...props}>
		<TextInput source="q" label="app.q" alwaysOn />
		<TextInput source="name" />
		{permissions && permissions("admin") && (
			<ReferenceInput source="user_id" reference="users">
				<AutocompleteInput optionText="username" />
			</ReferenceInput>
		)}
		<ReferenceInput source="carrier_id" reference="carriers">
			<SelectInput />
		</ReferenceInput>
	</Filter>
);
export default CarriersUserFilters;
