import polyglotI18nProvider from "ra-i18n-polyglot";
import { errorsReducer, errorsSaga } from "ra-remote-validator";
import React from "react";
import { Admin, resolveBrowserLocale, Resource } from "react-admin";
import { compose } from "recompose";
import addDashboardFeature from "./addDashboardFeature";
import addProductFeature from "./addProductFeature";
import addUploadFeature from "./addUploadFeature";
import authProvider from "./authProvider";
import CustomLoginPage from "./customLoginPage";
import Dashboard from "./dashboard";
import dataProvider from "./dataProvider";
import italianMessages from "./i18n/it";
import Layout from "./Layout";
import Menu from "./Menu";
import resources from "./resources";
import theme from "./theme";

const i18nProvider = polyglotI18nProvider(
	(locale) => italianMessages,
	resolveBrowserLocale()
);

const dp = compose(
	addProductFeature,
	addDashboardFeature,
	addUploadFeature
)(dataProvider);

const App = () => (
	<Admin
		menu={Menu}
		customSagas={[errorsSaga]}
		customReducers={{
			errors: errorsReducer,
		}}
		dataProvider={dp}
		authProvider={authProvider}
		i18nProvider={i18nProvider}
		loginPage={CustomLoginPage}
		dashboard={Dashboard}
		theme={theme}
		appLayout={Layout}
	>
		{resources.map((resource, i) => (
			<Resource key={i} {...resource} />
		))}
	</Admin>
);

export default App;
