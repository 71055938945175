import React from "react";
import {
	ChipField,
	CloneButton,
	Datagrid,
	EditButton,
	List,
	ReferenceField,
	TextField,
} from "react-admin";
import perPage from "../utils/perPage";
import Filters from "./Filters";

const CarriersUserList = ({ permissions, ...props }) => (
	<React.Fragment>
		<List
			{...props}
			perPage={perPage()}
			filters={<Filters permissions={permissions} />}
		>
			<Datagrid rowClick="edit">
				<TextField source="id" />
				<TextField source="name" style={{ fontWeight: "bold" }} />
				<ReferenceField source="carrier_id" reference="carriers">
					<ChipField source="name" />
				</ReferenceField>
				{permissions && permissions("admin") && (
					<ReferenceField source="user_id" reference="users">
						<ChipField source="username" />
					</ReferenceField>
				)}
				<EditButton />
				<CloneButton />
			</Datagrid>
		</List>
	</React.Fragment>
);
export default CarriersUserList;
