export default [
	{
		id: 1,
		name: "NSG",
	},
	{
		id: 2,
		name: "GreenFamily",
	},
];
