import { RoleIcon } from "../icons";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
export default {
	options: {
		group: "users",
		roles: ["admin"],
	},
	icon: RoleIcon,
	list: List,
	edit: Edit,
	create: Create,
};
