import { blue } from "@material-ui/core/colors";
import red from "@material-ui/core/colors/red";
import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
	palette: {
		background: {
			default: "#EDECEC",
		},
		primary: blue,
		secondary: blue,
		error: red,
		contrastThreshold: 3,
		tonalOffset: 0.3,
	},
	overrides: {},
});
