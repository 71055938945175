import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import { PRODUCT_CSV_HEADERS } from "../i18n/it";

const exporter = products => {
  const productsForExport = products.map(product => ({
    sku: product.code,
    description: product.name,
    quantity: product.stock ? product.stock.loaded : 0,
    price: product.price,
    cost: product.price * (product.stock ? product.stock.loaded : 0)
  }));
  let totalQuantity = productsForExport.reduce(
    (quantity, product) => quantity + product.quantity,
    0
  );
  let totalCost = productsForExport.reduce(
    (cost, product) => cost + product.cost,
    0
  );

  productsForExport.push({
    sku: "",
    description: "",
    quantity: totalQuantity,
    price: 0,
    cost: totalCost
  });
  jsonExport(
    productsForExport,
    {
      headers: ["sku", "description", "quantity", "price", "cost"],
      rename: PRODUCT_CSV_HEADERS
    },
    (err, csv) => {
      downloadCSV(csv, "products");
    }
  );
};
export default exporter;
