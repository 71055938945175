import React from "react";
import {
	BooleanField,
	ChipField,
	CloneButton,
	Datagrid,
	EditButton,
	List,
	ReferenceField,
	TextField,
} from "react-admin";

const StoreList = (props) => (
	<List {...props}>
		<Datagrid>
			<TextField source="id" />
			<TextField source="name" style={{ fontWeight: "bold" }} />
			<ReferenceField source="marketplace_id" reference="marketplaces">
				<ChipField source="name" />
			</ReferenceField>
			<BooleanField source="send_sms" />
			<EditButton />
			<CloneButton />
		</Datagrid>
	</List>
);
export default StoreList;
