import DashboardIcon from "@material-ui/icons/Dashboard";
import ProductItemIcon from "@material-ui/icons/Dns";
import {
  default as FaceIcon,
  default as UserIcon,
} from "@material-ui/icons/Face";
import InfoIcon from "@material-ui/icons/Info";
import {
  default as LabelIcon,
  default as ProductIcon,
} from "@material-ui/icons/Label";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import StorageIcon from "@material-ui/icons/LocationCity";
import RoleIcon from "@material-ui/icons/Lock";
import SaleChannelIcon from "@material-ui/icons/MonetizationOn";
import PrintTransactionIcon from "@material-ui/icons/Print";
import SendIcon from "@material-ui/icons/Send";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import SmsIcon from "@material-ui/icons/Sms";
import StoreIcon from "@material-ui/icons/Store";

const ShippingIcon = SendIcon;
const OrderIcon = ShoppingBasketIcon;

export {
  DashboardIcon,
  ProductIcon,
  ProductItemIcon,
  UserIcon,
  RoleIcon,
  PrintTransactionIcon,
  StorageIcon,
  SaleChannelIcon,
  ShoppingBasketIcon,
  SendIcon,
  FaceIcon,
  InfoIcon,
  LabelIcon,
  SmsIcon,
  StoreIcon,
  LocalShippingIcon,
  ShippingIcon,
  OrderIcon,
};

export default {
  ShippingIcon,
  OrderIcon,
};
