import React, { Fragment } from "react";
import { translate, withDataProvider } from "ra-core";
import { Button as RaButton } from "react-admin";
import { compose } from "recompose";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField
} from "@material-ui/core";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import { UNLOAD } from "../addProductFeature";
import beep from "../utils/beep";
import BarcodeTable from "./Unload/BarcodeTable";

class UnloadMassiveButton extends React.Component {
  state = {
    found: true,
    error: "",
    isOpen: false,
    barcode: "",
    quantity: 1,
    loading: false,
    data: []
  };
  handleClick = () => this.setState({ isOpen: true });
  handleDialogClose = () => this.setState({ isOpen: false });
  handleBarcode = e => {
    if (e.keyCode !== 13) {
      return;
    }

    const { translate } = this.props;
    const { data } = this.state;
    /**
     * ### LEGGIMI ###
     *
     * L'inventario utilizza la codifica EAN 13 quindi:
     * - Il primo digit viene scartato e quindi devo aggiungerlo, a noi è sempre 0.
     * - L'ultimo digit è un checksum e quindi va rimosso.
     */
    const barcode =
      "0" +
      (e.target.value.length > 11
        ? e.target.value.substr(0, 11)
        : e.target.value);
    let productItem = data.find(p => p.barcode === barcode);
    if (productItem) {
      let newData = [productItem].concat(data.filter(d => d !== productItem));
      this.setState(
        {
          data: newData,
          barcode: "",
          error: translate(
            "resources.product-items.messages.unload_errors.barcode_already_added"
          )
        },
        () => {
          beep(2) && this.refs.barcodeInput.focus();
        }
      );
      return;
    }

    const { dataProvider } = this.props;
    dataProvider(UNLOAD, "products", {
      barcode,
      quantity: this.state.quantity
    }).then(({ data: { process, code, message } }) => {
      beep(1);

      if (code === 200) {
        this.setState(
          { error: null, data: process.concat(this.state.data), barcode: "" },
          () => this.refs.barcodeInput.focus()
        );
      } else {
        this.setState({ error: message });
      }
    });
  };
  handleBarcodeChange = e => this.setState({ barcode: e.target.value });

  handleQuantityChange = e => this.setState({ quantity: e.target.value });

  handleConfirm = ({ data: { process } }) => this.setState({ data: process });

  handleTableClear = () => this.setState({ data: [] });

  render() {
    const { translate } = this.props;
    return (
      <Fragment>
        <RaButton
          onClick={this.handleClick}
          label={"resources.product-items.buttons.unload_massive"}
        >
          <LabelImportantIcon />
        </RaButton>
        <Dialog
          maxWidth="md"
          fullWidth
          open={this.state.isOpen}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>
            {translate("resources.product-items.titles.unload_massive")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {translate("resources.product-items.messages.unload_massive")}
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <TextField
                  ref="quantityInput"
                  key="quantityInput"
                  fullWidth
                  value={this.state.quantity}
                  label={translate("resources.product-items.fields.quantity")}
                  onChange={this.handleQuantityChange}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  ref="barcodeInput"
                  key="barcodeInput"
                  fullWidth
                  autoFocus
                  helperText={this.state.error}
                  error={
                    this.state.error !== null && this.state.error.length > 0
                  }
                  disabled={this.state.loading}
                  label={translate("resources.product-items.fields.barcode")}
                  type="text"
                  value={this.state.barcode}
                  onKeyDown={this.handleBarcode}
                  onChange={this.handleBarcodeChange}
                />
              </Grid>
            </Grid>
            <BarcodeTable rows={this.state.data} />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={this.state.data.length === 0}
              color="secondary"
              onClick={this.handleTableClear}
            >
              {translate("app.action.clear")}
            </Button>
            <Button color="secondary" onClick={this.handleDialogClose}>
              {translate("ra.action.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default compose(translate, withDataProvider)(UnloadMassiveButton);
