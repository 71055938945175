import React from "react";
import { Filter, TextInput } from "react-admin";
const CustomerFilters = (props) => (
	<Filter {...props}>
		<TextInput source="q" label="app.q" alwaysOn />
		<TextInput source="first_name" allowEmpty />
		<TextInput source="last_name" allowEmpty />
		<TextInput source="address" allowEmpty />
		<TextInput source="city" allowEmpty />
		<TextInput source="zip_code" allowEmpty />
		<TextInput source="country" />
		<TextInput source="phone" allowEmpty />
		<TextInput source="email" allowEmpty />
	</Filter>
);
export default CustomerFilters;
