import { Grid } from "@material-ui/core";
import React from "react";
import {
	AutocompleteArrayInput,
	NumberInput,
	ReferenceArrayInput,
	ReferenceInput,
	required,
	SelectInput,
	TextInput,
} from "react-admin";
import { FormatMany, ParseMany } from "../utils/parseFormat";
import paymentMethods from "./payment-methods";

const OrderForm = (props) => (
	<Grid container spacing={1}>
		<Grid item md={3}>
			<TextInput source="code" disabled {...props} />
		</Grid>
		<Grid item md={3}>
			<ReferenceInput
				source="status_id"
				reference="statuses"
				{...props}
				sort={{ field: "id", direction: "asc" }}
				perPage={100}
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
		</Grid>
		<Grid item md={3}>
			<SelectInput
				source="payment_method"
				choices={paymentMethods}
				disabled
				{...props}
			/>
		</Grid>
		<Grid item md={3}>
			<NumberInput source="amount" validate={[required()]} {...props} />
		</Grid>
		<Grid item md={6}>
			<ReferenceArrayInput
				source="tags"
				reference="tags"
				format={FormatMany}
				parse={ParseMany}
				{...props}
			>
				<AutocompleteArrayInput />
			</ReferenceArrayInput>
		</Grid>
		<Grid item md={6}></Grid>
		<Grid item md={6}>
			<TextInput source="notes" multiline rows={5} {...props} />
		</Grid>
		<Grid item md={6}>
			<TextInput source="notes_internal" multiline rows={5} {...props} />
		</Grid>
	</Grid>
);

export default OrderForm;
