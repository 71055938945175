import RemoteErrorsInteceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	Create,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";
import { messageHelper } from "./helpers";

const SmsTemplateCreate = ({ dispatch, validate, errors, ...props }) => {
	return (
		<Create {...props}>
			<SimpleForm redirect="list" validate={validate}>
				<RemoteErrorsInteceptor errors={errors} dispatch={dispatch} />
				<TextInput source="code" validate={[required()]} />
				<TextInput source="name" validate={[required()]} />
				<ReferenceInput
					label="Status"
					source="status_id"
					reference="statuses"
					perPage={100}
					allowEmpty
				>
					<SelectInput optionText="name" />
				</ReferenceInput>
				<TextInput
					source="message"
					multiline
					rows={5}
					validate={[required()]}
					helperText={messageHelper}
				/>
				<TextInput
					source="delay"
					helperText="+7 minutes, +1 day, + 2days, +1 month, ecc..."
				/>
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(SmsTemplateCreate);
