import React from "react";
import { Edit, SimpleForm, TextInput, required } from "react-admin";

const SaleChannelEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);
export default SaleChannelEdit;
