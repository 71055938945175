import RemoteErrorsInteceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	BooleanInput,
	CheckboxGroupInput,
	Create,
	FormTab,
	ReferenceArrayInput,
	TabbedForm,
	TextInput,
	useTranslate,
} from "react-admin";
import { compose } from "recompose";
import { FormatMany, ParseMany } from "../utils/parseFormat";

const MyCheckboxGroupInput = ({ loaded, ...props }) => (
	<CheckboxGroupInput {...props} choices={props.choices || []} />
);

const UserCreate = ({ dispatch, validate, errors, ...props }) => {
	const translate = useTranslate();
	return (
		<Create {...props}>
			<TabbedForm redirect="list" validate={validate}>
				<FormTab label={translate("resources.users.name", 1)}>
					<RemoteErrorsInteceptor errors={errors} dispatch={dispatch} />
					<BooleanInput source="is_active" />
					<TextInput source="email" />
					<TextInput source="username" />
					<TextInput source="password" type="password" />
					<TextInput source="profile.name" />
					<TextInput source="profile.surname" />
				</FormTab>
				<FormTab label={translate("resources.roles.name", 0)}>
					<ReferenceArrayInput
						format={FormatMany}
						parse={ParseMany}
						source="roles"
						reference="roles"
						fullWidth
					>
						<MyCheckboxGroupInput />
					</ReferenceArrayInput>
				</FormTab>
				<FormTab label={translate("resources.stores.name", 0)}>
					<ReferenceArrayInput
						format={FormatMany}
						parse={ParseMany}
						source="stores"
						reference="stores"
						fullWidth
					>
						<MyCheckboxGroupInput />
					</ReferenceArrayInput>
				</FormTab>
			</TabbedForm>
		</Create>
	);
};
export default compose(withErrors)(UserCreate);
