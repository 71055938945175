import List from "./List";
import Edit from "./Edit";
import Create from "./Create";
import { ProductIcon } from "../icons";
export default {
  options: {
    group: "manage",
    roles: ["admin", "stores"],
  },
  icon: ProductIcon,
  list: List,
  edit: Edit,
  create: Create,
};
