import { Grid } from "@material-ui/core";
import React from "react";
import {
	AutocompleteInput,
	ReferenceInput,
	required,
	TextInput,
} from "react-admin";

const CustomerForm = ({ source = "", ...props }) => (
	<Grid container spacing={1}>
		{source === "customer." && (
			<React.Fragment>
				<Grid item md={4}>
					<ReferenceInput
						label="ID"
						source={`${source}id`}
						reference="customers"
						allowEmpty
						emptyText="resources.customers.create"
						{...props}
					>
						<AutocompleteInput optionText="fullname" />
					</ReferenceInput>
				</Grid>
				<Grid item md={8}></Grid>
			</React.Fragment>
		)}
		<Grid item md={4}>
			<TextInput
				label="resources.customers.fields.first_name"
				source={`${source}first_name`}
				validate={[required()]}
				{...props}
			/>
		</Grid>
		<Grid item md={4}>
			<TextInput
				label="resources.customers.fields.last_name"
				source={`${source}last_name`}
				validate={[required()]}
				{...props}
			/>
		</Grid>
		<Grid item md={4}></Grid>
		<Grid item md={4}>
			<TextInput
				label="resources.customers.fields.address"
				source={`${source}address`}
				{...props}
			/>
		</Grid>
		<Grid item md={3}>
			<TextInput
				label="resources.customers.fields.city"
				source={`${source}city`}
				{...props}
			/>
		</Grid>
		<Grid item md={2}>
			<TextInput
				label="resources.customers.fields.province"
				source={`${source}province`}
				{...props}
			/>
		</Grid>
		<Grid item md={3}>
			<TextInput
				label="resources.customers.fields.zip_code"
				source={`${source}zip_code`}
				{...props}
			/>
		</Grid>
		<Grid item md={4}>
			<TextInput
				label="resources.customers.fields.country"
				source={`${source}country`}
				{...props}
			/>
		</Grid>
		<Grid item md={8}></Grid>
		<Grid item md={6}>
			<TextInput
				label="resources.customers.fields.phone"
				source={`${source}phone`}
				{...props}
			/>
		</Grid>
		<Grid item md={6}>
			<TextInput
				label="resources.customers.fields.email"
				source={`${source}email`}
				{...props}
			/>
		</Grid>
		<Grid item md={4}>
			<TextInput
				multiline
				rows={5}
				label="resources.customers.fields.notes"
				source={`${source}notes`}
				{...props}
			/>
		</Grid>
	</Grid>
);

export default CustomerForm;
