import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	AutocompleteInput,
	Edit,
	FormDataConsumer,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
	useTranslate,
} from "react-admin";
import { compose } from "recompose";
import Title from "../components/Title";
import CarriersUsersPropsInput from "./CarriersUsersPropsInput";

const CarriersUserEdit = ({
	dispatch,
	validate,
	errors,
	permissions,
	...props
}) => {
	const translate = useTranslate();
	return (
		<Edit title={<Title source="name" />} {...props} undoable={false}>
			<SimpleForm validate={validate}>
				<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
				<TextInput validate={[required()]} source="name" />
				{permissions && permissions("admin") && (
					<ReferenceInput
						source="user_id"
						reference="users"
						label={translate("resources.users.name", 1)}
						validate={[required()]}
					>
						<AutocompleteInput optionText="username" />
					</ReferenceInput>
				)}
				<ReferenceInput
					source="carrier_id"
					reference="carriers"
					label={translate("resources.carriers.name", 1)}
					validate={[required()]}
				>
					<SelectInput />
				</ReferenceInput>
				<FormDataConsumer>
					{({ formData, scopedFormData, getSource, ...rest }) => {
						const { carrier_id: carrierId } = formData || {};
						if (!carrierId) {
							return null;
						}
						return (
							<CarriersUsersPropsInput
								source="carriers_users_props"
								carrierId={carrierId}
							/>
						);
					}}
				</FormDataConsumer>
			</SimpleForm>
		</Edit>
	);
};
export default compose(withErrors)(CarriersUserEdit);
