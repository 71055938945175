import React from "react";
import { ChipField, Datagrid, EditButton, List, TextField } from "react-admin";
import { ColorField } from "react-admin-color-input";
import perPage from "../utils/perPage";
import Filters from "./Filters";

const StatusList = (props) => (
	<React.Fragment>
		<List {...props} perPage={perPage()} filters={<Filters />}>
			<Datagrid rowClick="edit">
				<TextField source="id" />
				<TextField source="name" style={{ fontWeight: "bold" }} />
				<ChipField source="code" />
				<TextField source="description" />
				<ColorField source="color" />
				<EditButton />
			</Datagrid>
		</List>
	</React.Fragment>
);
export default StatusList;
