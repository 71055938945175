import React from "react";
import {
	ArrayField,
	BooleanField,
	ChipField,
	Datagrid,
	EditButton,
	List,
	SingleFieldList,
	TextField,
} from "react-admin";
import Filters from "./Filters";
import LoginButton from "./LoginButton";

const UserList = (props) => (
	<React.Fragment>
		<List {...props} perPage={10} filters={<Filters />}>
			<Datagrid rowClick="edit">
				<TextField source="id" />
				<BooleanField source="is_active" />
				<TextField source="username" style={{ fontWeight: "bold" }} />
				<ArrayField source="roles">
					<SingleFieldList>
						<ChipField source="name" />
					</SingleFieldList>
				</ArrayField>
				<LoginButton />
				<EditButton />
			</Datagrid>
		</List>
	</React.Fragment>
);
export default UserList;
