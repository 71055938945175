import React, { Fragment } from "react";
import { BulkDeleteButton } from "react-admin";
import PrintButton from "./PrintButton";

const ProductBulkActions = props => (
  <Fragment>
    <PrintButton {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);
export default ProductBulkActions;
