import React, { Fragment, Component } from "react";

import { withDataProvider, GET_LIST } from "ra-core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import makeid from "../utils/makeid";
import { compose } from "recompose";
import { LOAD } from "../addProductFeature";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  TextField
} from "@material-ui/core";
import {
  Mutation,
  translate,
  Button as RaButton,
  useRefresh,
  useNotify
} from "react-admin";
import { API_URL } from "../config";
import { getToken } from "../authHeaders";
import Autocomplete from "@material-ui/lab/Autocomplete";

const ConfirmButton = compose(translate)(
  ({ translate, payload, closeRequest, ...props }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const options = {
      undoable: false,
      onSuccess: ({ data }) => {
        notify("resources.product-items.messages.load_completed");
        refresh();
        let printUrl = `${API_URL}/print-transactions/${
          data.print_transaction_id
        }.pdf?token=${getToken()}`;
        document.location.href = printUrl;
      },
      onError: {
        notification: {
          body: "resources.product-items.messages.load_fails",
          level: "warning"
        }
      }
    };
    return (
      <Mutation
        type={LOAD}
        resource="product-items"
        payload={payload}
        options={options}
      >
        {load => (
          <Button color="primary" onClick={() => !closeRequest() && load()}>
            {translate("ra.action.confirm")}
          </Button>
        )}
      </Mutation>
    );
  }
);

class LoadButton extends Component {
  state = {
    isOpen: false,
    products: [],
    payload: {
      product_id: "",
      size: 10
    },
    session: makeid(10)
  };

  componentDidMount() {
    const { dataProvider } = this.props;
    dataProvider(GET_LIST, "products", {
      pagination: {
        page: 1,
        perPage: 9999
      },
      sort: {
        field: "name",
        order: "DESC"
      }
    }).then(({ data }) =>
      this.setState({
        products: data,
        payload: {
          ...this.state.payload,
          product_id: data.length > 0 ? data[0].id : null
        }
      })
    );
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { translate } = this.props;
    return (
      <Fragment>
        <RaButton
          label={"resources.product-items.buttons.load"}
          onClick={this.handleClick}
        >
          <AddBoxIcon />
        </RaButton>
        <Dialog open={this.state.isOpen} onClose={this.handleDialogClose}>
          <DialogTitle>
            {translate("resources.product-items.titles.load")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {translate("resources.product-items.messages.load")}
            </DialogContentText>
            <TextField
              fullWidth
              label={translate("resources.product-items.fields.size")}
              type="number"
              min={10}
              max={100}
              value={this.state.payload.size}
              onChange={e =>
                this.setState({
                  payload: {
                    ...this.state.payload,
                    size: e.target.value
                  }
                })
              }
            />
            <Autocomplete
              style={{ marginTop: 16 }}
              options={this.state.products}
              getOptionLabel={product => product.name}
              onChange={(e, product) =>
                this.setState({
                  payload: {
                    ...this.state.payload,
                    product_id: product && product !== null ? product.id : null
                  }
                })
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label={translate(
                    "resources.product-items.fields.product.name"
                  )}
                  fullWidth
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.handleDialogClose}>
              {translate("ra.action.cancel")}
            </Button>
            <ConfirmButton
              closeRequest={this.handleDialogClose}
              payload={this.state.payload}
            />
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}
export default compose(translate, withDataProvider)(LoadButton);
