export default (product, saleChannel) => {
  let profit =
    product && product.final_price > 0
      ? (saleChannel ? saleChannel.price : 0) - product.final_price * 1.22
      : 0;
  if (saleChannel.sale_channel_id === 3) {
    // (((PREZZO CANALE VENDITA AMAZON + 4,99) - ((PREZZO CANALE VENDITA AMAZON+4,99)*0,15)) - (COSTO FINITO PRODOTTO + 3,80)*1,22)) / 1,22
    profit =
      (saleChannel.price +
        4.99 -
        (saleChannel.price + 4.99) * 0.15 -
        (product.final_price + 3.8) * 1.22) /
      1.22;
  }
  return profit;
};
