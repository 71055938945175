import { amber, green, purple, red, yellow } from "@material-ui/core/colors";
import ClearIcon from "@material-ui/icons/Clear";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import HomeIcon from "@material-ui/icons/Home";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";

export default [
	{
		id: "pending",
		name: "resources.shippings.fields.statuses.pending",
		color: yellow[500],
		Icon: HourglassEmptyIcon,
	},
	{
		id: "transmitted",
		name: "resources.shippings.fields.statuses.transmitted",
		color: purple[500],
		Icon: LocalShippingIcon,
	},
	{
		id: "delivering",
		name: "resources.shippings.fields.statuses.delivering",
		color: amber[500],
		Icon: FlightTakeoffIcon,
	},
	{
		id: "delivery-first-fault",
		name: "resources.shippings.fields.statuses.delivery-first-fault",
		color: red[700],
		Icon: ClearIcon,
	},
	{
		id: "delivered",
		name: "resources.shippings.fields.statuses.delivered",
		color: green[500],
		Icon: HomeIcon,
	},
	{
		id: "rejected",
		name: "resources.shippings.fields.statuses.rejected",
		color: red[500],
		Icon: ThumbDownIcon,
	},
];
