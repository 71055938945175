import React from "react";
import { Filter, TextInput, ReferenceInput, SelectInput } from "react-admin";
const ProductFilters = props => (
  <Filter {...props}>
    <TextInput key="q" label="Search" source="q" alwaysOn />
    <ReferenceInput
      label="resources.products.fields.store.name"
      source="store_id"
      reference="stores"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);
export default ProductFilters;
