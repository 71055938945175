/**
 * https://material-ui.com/components/material-icons/
 */
import { FaceIcon } from "../icons";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";

export default {
  options: {
    group: "shipping",
    roles: ["admin", "shippings"],
  },
  icon: FaceIcon,
  list: List,
  edit: Edit,
  create: Create,
};
