import PrintIcon from "@material-ui/icons/Print";
import React, { Fragment } from "react";
import { Button, useRefresh } from "react-admin";
import { PRINT_LABEL_URL } from "../config";

const PrintLabelButton = ({ selectedIds }) => {
	const authToken = localStorage.getItem("token");
	const ids = selectedIds.join(",");
	const refresh = useRefresh();
	return (
		<Fragment>
			<Button
				onClick={() => setTimeout(() => refresh("shippings"), 2000)}
				href={`${PRINT_LABEL_URL}?ids=${ids}&token=${authToken}`}
				target="_blank"
				rel="noopener noreferrer"
				label="resources.shippings.print"
			>
				<PrintIcon />
			</Button>
		</Fragment>
	);
};

export default PrintLabelButton;
