import { PrintTransactionIcon } from "../icons";
import List from "./List";

export default {
  icon: PrintTransactionIcon,
  options: {
    group: "manage",
    roles: ["admin", "stores"],
  },
  list: List,
};
