import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	BooleanInput,
	CheckboxGroupInput,
	Edit,
	FormTab,
	ReferenceArrayInput,
	TabbedForm,
	TextInput,
	useTranslate,
} from "react-admin";
import { compose } from "recompose";
import { FormatMany, ParseMany } from "../utils/parseFormat";

const MyCheckboxGroupInput = ({ loaded, ...props }) => (
	<CheckboxGroupInput {...props} choices={props.choices || []} />
);

const UserEdit = ({ dispatch, validate, errors, ...props }) => {
	const translate = useTranslate();
	return (
		<Edit {...props} undoable={false}>
			<TabbedForm validate={validate}>
				<FormTab label={translate("resources.users.name", 1)}>
					<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
					<BooleanInput source="is_active" />
					<TextInput source="email" />
					<TextInput source="username" />
					<TextInput source="password" type="password" />
					<TextInput source="profile.name" />
					<TextInput source="profile.surname" />
				</FormTab>
				<FormTab label={translate("resources.roles.name", 0)}>
					<ReferenceArrayInput
						format={FormatMany}
						parse={ParseMany}
						source="roles"
						reference="roles"
						fullWidth
					>
						<MyCheckboxGroupInput />
					</ReferenceArrayInput>
				</FormTab>
				<FormTab label={translate("resources.stores.name", 0)}>
					<ReferenceArrayInput
						format={FormatMany}
						parse={ParseMany}
						source="stores"
						reference="stores"
						fullWidth
					>
						<MyCheckboxGroupInput />
					</ReferenceArrayInput>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
export default compose(withErrors)(UserEdit);
