import { SendIcon } from "../icons";
import Edit from "./Edit";
import List from "./List";

export default {
  options: {
    group: "shipping",
    roles: ["admin", "shippings"],
  },
  icon: SendIcon,
  list: List,
  edit: Edit,
};
