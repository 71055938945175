import { API_URL } from "./config";
import getHeaders from "./authHeaders";
import { fetchUtils } from "react-admin";

export const LOAD = "LOAD";
export const UNLOAD = "UNLOAD";
export const RESOLVE = "RESOLVE";

const addProductFeature = requestHandler => (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  if (type === LOAD) {
    let url = `${API_URL}/${resource}/load`;
    return fetchJson(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers: getHeaders()
    }).then(({ json: { data } }) => ({
      data
    }));
  } else if (type === UNLOAD) {
    let url = `${API_URL}/${resource}/unload`;
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers: getHeaders()
    })
      .then(response => response.json())
      .then(({ data }) => ({
        data
      }));
  } else if (type === RESOLVE) {
    let url = `${API_URL}/${resource}/resolve/${params.barcode}`;
    return fetchJson(url, {
      method: "GET",
      headers: getHeaders()
    }).then(({ json: { data } }) => ({ data }));
  }
  return requestHandler(type, resource, params);
};
export default addProductFeature;
