// in ./StatusButton.js
import LabelIcon from "@material-ui/icons/Label";
import React, { Fragment, useState } from "react";
import {
	Button,
	Confirm,
	useNotify,
	useRefresh,
	useUnselectAll,
	useUpdateMany,
} from "react-admin";

const DeleteLabelButton = ({ basePath, resource, selectedIds }) => {
	const [open, setOpen] = useState(false);

	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll();
	const [updateMany, { loading }] = useUpdateMany(
		`${resource}/delete-shipping`,
		selectedIds,
		{ delete_label: true },
		{
			onSuccess: () => {
				refresh();
				notify("resources.orders.shipping.deleted");
				unselectAll("orders");
				setTimeout(() => setOpen(false), 500);
			},
			onFailure: (error) =>
				notify("resources.orders.shipping.error", "warning"),
		}
	);

	return (
		<Fragment>
			<Button
				onClick={() => setOpen(true)}
				label="resources.orders.shipping.delete_label"
				color="secondary"
				disabled={loading}
			>
				<LabelIcon />
			</Button>
			<Confirm
				isOpen={open}
				title="resources.orders.shipping.delete_label"
				content="resources.orders.shipping.delete_label_confirm"
				onConfirm={updateMany}
				onClose={() => setOpen(false)}
				loading={loading}
			/>
		</Fragment>
	);
};

export default DeleteLabelButton;
