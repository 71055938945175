import React from "react";
import {
	AutocompleteInput,
	Filter,
	NumberInput,
	ReferenceInput,
	SelectInput,
	TextInput,
	useTranslate,
} from "react-admin";
import { DateTimeInput } from "../components/DatePickers";

const OrderFilters = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>
			<TextInput source="q" label="app.q" alwaysOn />
			<DateTimeInput source="created_start" alwaysOn />
			<DateTimeInput source="created_end" alwaysOn />
			<ReferenceInput
				label="Status"
				source="status_id"
				reference="statuses"
				allowEmpty
				alwaysOn
				sort={{ field: "id", direaction: "asc" }}
				perPage={100}
			>
				<SelectInput optionText="name" />
			</ReferenceInput>
			<ReferenceInput
				source="store_id"
				reference="stores"
				sort={{ field: "name", order: "ASC" }}
				alwaysOn
			>
				<SelectInput />
			</ReferenceInput>
			<ReferenceInput source="customer_id" reference="customers" allowEmpty>
				<AutocompleteInput optionText="fullname" />
			</ReferenceInput>
			<ReferenceInput
				source="marketplace_id"
				reference="marketplaces"
				sort={{ field: "name", order: "ASC" }}
			>
				<SelectInput />
			</ReferenceInput>
			<ReferenceInput source="carrier_user_id" reference="carriers-users">
				<SelectInput />
			</ReferenceInput>
			<ReferenceInput label="Tags" source="tags" reference="tags" allowEmpty>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<TextInput source="payment_method" />
			<NumberInput source="amount" />
			<TextInput
				source="item_name"
				label={translate("resources.order_items.name", 1)}
			/>
		</Filter>
	);
};
export default OrderFilters;
