import PrintIcon from "@material-ui/icons/Print";
import React from "react";
import { Button, useTranslate } from "react-admin";
import { PRINT_URL } from "../config";

const PrintButton = ({ selectedIds }) => {
	const translate = useTranslate();
	const authToken = localStorage.getItem("token");
	const ids = selectedIds.join(",");
	return (
		<Button
			href={`${PRINT_URL}?ids=${ids}&token=${authToken}`}
			target="_blank"
			rel="noopener noreferrer"
			label={translate("resources.orders.print")}
		>
			<PrintIcon />
		</Button>
	);
};

export default PrintButton;
