import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	BooleanInput,
	Edit,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import { compose } from "recompose";
import Title from "../components/Title";
import statuses from "./statuses";

const ShippingEdit = ({ dispatch, validate, errors, ...props }) => (
	<Edit title={<Title source="name" />} {...props} undoable={false}>
		<SimpleForm validate={validate}>
			<RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
			<TextInput source="tracking" disabled />
			<SelectInput choices={statuses} source="status" />
			<BooleanInput source="label_printed" />
		</SimpleForm>
	</Edit>
);
export default compose(withErrors)(ShippingEdit);
