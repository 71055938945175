import { createStyles, List, makeStyles, withStyles } from "@material-ui/core";
import SubMenuIcon from "@material-ui/icons/ChevronRight";
import { WithPermissions } from "ra-core";
import React, { createElement, useState } from "react";
import { getResources, MenuItemLink, Responsive, translate } from "react-admin";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import customRoutes from "./customRoutes";
import { DashboardIcon } from "./icons";
import SubMenu from "./SubMenu";

const groupOrders = {
	dashboard: 1,
	manage: 2,
	shipping: 3,
	admin: 4,
	users: 5,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
		marginRight: theme.spacing(1),
		paddingTop: theme.spacing(1),
		boxShadow: theme.shadows[24],
		[theme.breakpoints.up("md")]: {
			height: "100%",
		},
		[theme.breakpoints.down("md")]: {
			paddingBottom: "6em",
		},
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

const styles = (theme) =>
	createStyles({
		version: {
			padding: theme.spacing(1),
		},
	});

const mapGroups = (resources, permissions, hasDashboard) => {
	let groups = (hasDashboard
		? [
				{
					path: "/",
					name: "dashboard",
					icon: DashboardIcon,
					options: {
						group: "dashboard",
						accessible: true,
					},
				},
		  ]
		: []
	)
		.concat(resources.filter((r) => r.hasList))
		.concat(
			customRoutes.map((cr) => ({
				path: cr.props.path,
				icon: cr.props.options.icon,
				options: cr.props.options,
			}))
		)
		.filter(
			(item) =>
				permissions &&
				(item.options.roles === undefined ||
					item.options.roles.filter((role) => permissions(role)).length > 0)
		)
		.reduce((groups, resource) => {
			let groupName = resource.options ? resource.options.group : "";
			let group = groups.find((g) => g.name === groupName);
			if (group) {
				group.resources.push(resource);
			} else {
				group = {
					name: groupName,
					order: groupOrders[groupName] || 1000,
					resources: [resource],
				};
				groups.push(group);
			}
			return groups;
		}, []);
	groups.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));

	return groups;
};
const Menu = ({
	resources,
	onMenuClick,
	logout,
	open,
	classes,
	translate,
	location,
	hasDashboard,
}) => {
	const [visibility, setVisibility] = useState({
		manage: true,
		shipping: true,
		dashboard: true,
		admin: true,
		users: true,
	});
	const customClasses = useStyles();
	return (
		<WithPermissions
			render={({ permissions }) => (
				<List component="nav" className={customClasses.root}>
					{mapGroups(resources, permissions, hasDashboard).map((group) => (
						<SubMenu
							key={group.name}
							handleToggle={() =>
								setVisibility({
									...visibility,
									[group.name]: visibility[group.name] !== true,
								})
							}
							isOpen={
								visibility[group.name] ||
								group.resources.some(
									(resource) =>
										location.pathname === `/${resource.name}` ||
										location.pathname === resource.path ||
										location.pathname.indexOf(`/${resource.name}?`) === 0 ||
										location.pathname.indexOf(`/${resource.name}/`) === 0
								)
							}
							sidebarIsOpen={open}
							name={`menu.groups.${group.name}`}
							icon={<SubMenuIcon />}
						>
							{group.resources.map((resource) => {
								let to = `${resource.path || `/${resource.name}`}`;
								return (
									<MenuItemLink
										key={resource.path || resource.name}
										to={to}
										isActive={(match, location) =>
											location.pathname === `/${resource.name}` ||
											location.pathname === resource.path ||
											location.pathname.indexOf(`/${resource.name}?`) === 0 ||
											location.pathname.indexOf(`/${resource.name}/`) === 0
										}
										primaryText={
											resource.options && resource.options.title
												? resource.options.title
												: resource.options && resource.options.label
												? translate(resource.options.label)
												: translate(`menu.items.${resource.name}`)
										}
										leftIcon={createElement(resource.icon)}
										onClick={onMenuClick}
										button
									/>
								);
							})}
						</SubMenu>
					))}
					<Responsive
						small={logout}
						medium={null} // Pass null to render nothing on larger devices
					/>
				</List>
			)}
		/>
	);
};

const mapStateToProps = (state) => ({
	open: state.admin.ui.sidebarOpen,
	resources: getResources(state),
});

export default translate(
	withRouter(connect(mapStateToProps)(withStyles(styles)(Menu)))
);
