import { merge } from "lodash";
import italianMessages from "ra-language-italian";
export const PRODUCT_CSV_HEADERS = [
	"SKU",
	"Descrizione",
	"Quantità",
	"Prezzo",
	"Valore Inventario",
];
const standardMessages = {
	"Unprocessable Entity": "Errore di validazione",
};
const appMessages = {
	ra: {
		message: {
			none: "Nessuno",
		},
		page: {
			empty: "Nessun risultato trovato",
			invite: "Clicca il pulsante sottostante per aggiungere risorse",
		},
		action: {
			import: "Importa",
			remove: "Rimuovi",
			send: "Invia",
			unselect: "Deseleziona",
		},
		date: {
			today: "Oggi",
		},
		auth: {
			sub_sign_out: "Smetti di impersonare",
			sign_in_success: "Login effettuato correttamente",
			auth_check_error: "Impossibile loggarsi",
		},
	},
	menu: {
		groups: {
			dashboard: "Dashboard",
			manage: "Magazzino",
			shipping: "Spedizione",
			admin: "Amministrazione",
			users: "Utenti",
		},
		items: {
			products: "Prodotti",
			users: "Utenti",
			roles: "Ruoli",
			stores: "Magazzini",
			customers: "Clienti",
			marketplaces: "Marketplaces",
			orders: "Ordini",
			tags: "Tags",
			statuses: "Status",
			shippings: "Spedizioni",
			carriers: "Corrieri",
			dashboard: "Statistiche",
			"sms-templates": "Template SMS",
			"sale-channels": "Canali di vendita",
			"product-items": "Etichette",
			"print-transactions": "Stampe",
			"carriers-users": "Profili spedizione",
		},
	},
	app: {
		q: "Cerca...",
		no_records: "Nessun record restitutio",
		action: {
			clear: "Pulisci",
			print: "Stampa",
		},
	},
	dashboard: {
		filters: {
			from: "Dalla data",
			to: "Alla data",
		},
		no_data: "Nessun dato presente!",
		total_orders: "Ordini",
		total_shippings: "Spedizioni",
		total_loaded_product_items: "Prodotti caricati",
		total_unloaded_product_items: "Prodotti scaricati",
		total_pending_orders: "Ordini in attesa",
		total_cod_delivered_orders: "Numero di ordini in contrassegno consegnati",
		total_cod_delivered_orders_amount: "Importo totale contrassegni consegnati",
		total_cod_rejected_orders: "Numero di ordini in contrassegno rifiutati",
		total_prepaid_delivered_orders: "Numero totale ordini consegnati prepagati",
		total_prepaid_delivered_orders_amount:
			"Importo totale consegnati prepagati",
		total_rejected_orders_perc: "Percentuale ordini rifiutati",

		last_received_orders: {
			title: "Ultimi ordini ricevuti",
			columns: {
				code: "Codice",
				store: { name: "Negozio" },
				customer: {
					name: "Nome",
					surname: "Cognome",
					fullname: "Nominativo",
				},
			},
		},
		last_created_shippings: {
			title: "Ultime spedizioni create",
			columns: {
				code: "Codice",
				tracking: "Tracking",
				status: "Stato",
			},
		},
	},
	resources: {
		"print-transactions": {
			name: "Stampe |||| Stampa",
			fields: {
				name: "Identificativo",
				size: "Barcodes",
				created: "Data creazione",
				from: "Dalla data",
				to: "Alla data",
			},
		},
		"product-items": {
			name: "Etichetta |||| Etichette",
			fields: {
				product: { name: "Prodotto" },
				quantity: "Quantità",
				status: "Stato",
				modified: "Modificato il",
				barcode: "Codice a barre",
				created: "Data creazione",
				assigned: "Data assegnazione",
				printed: "Data di stampa",
				size: "Numero di etichette da generare",
				process_seq: "Processa automaticamente",
				print_transaction_id: "Stampa",
				process_seq_help: [
					"Scarica i barcode evitando di dover premere il tasto conferma.",
				].join(" "),
			},
			status: {
				loaded: "Caricato",
				unloaded: "Scaricato",
			},
			buttons: {
				load: "Carica",
				unload: "Scarica",
				unload_massive: "Scarica Massivo",
				print: "Stampa Etichette",
			},
			titles: {
				load: "Carica nuove etichette",
				unload: "Scarica etichette",
				unload_massive: "Scarico massivo",
				print: "Stampa Etichette",
			},
			messages: {
				unload_massive_completed: "Scarico massivo delle etichette completato",
				unload_massive_fails: "Scarico massivo delle etichette fallito.",
				unload_massive: [
					"Specifica una quantità da scaricare, posiziona il cursore",
					"sul campo barcode e scansiona il barcode del prodotto per cui",
					"vuoi scaricare la quantità specificata.",
				].join(" "),
				unload_completed: "Scarico delle etichette completato.",
				unload_fails: "Scarico delle etichette fallito.",
				unload: "Scansiona le etichette che vuoi scaricare.",
				unload_errors: {
					barcode_already_added: "Codice a barre già presente",
					barcode_already_unloaded: "Codice a barre già scaricato",
					barcode_not_found: "Codice a barre non trovato",
				},
				load_completed: "Carico delle nuove etichette completata.",
				load_fails:
					"Si è verificato un errore per cui non è stato possibile generare le etichette",
				load: "Seleziona la tipologia e il numero di etichette da generare.",
				print: [
					"Confermando questa operazione la data di stampa di tutte le etichette selezionate",
					"sarà aggiornata impostando data e ora correnti e successivamente otterrai un file",
					"in formato PDF contenente tutte le etichette selezionate nel formato stampa predefinito.",
					"L'aggiornamento della data di stampa non inficia su eventuali operazioni future, potrai sempre rieseguire",
					"la ristampa di etichette che hai già stampato in precedenza.",
				].join(" "),
				print_single: [
					"Abbiamo notato che hai selezionato una sola etichetta, ",
					"quindi puoi impostare la posizione sul foglio di stampa.",
				].join(" "),
				print_borders: [
					"Stampa bordi (utili se devi ritagliare le etichette)",
					"su foglio adesivo completo",
				].join(" "),
			},
		},
		products: {
			name: "Prodotto |||| Prodotti",
			fields: {
				code: "Codice",
				store_id: "Magazzino",
				store: {
					name: "Magazzino",
				},
				profit: "Utile Netto",
				sale_channels: "Canali di vendita (IVA INC.)",
				sale_channel_id: "Canale",
				code_help: [
					"Codice identificativo del prodotto, può contenere lettere, numeri e trattini",
					"ma non può assolutamente contenere spazi poiché sarà stampato direttamente",
					"all'interno delle etichette.",
				].join(" "),
				name: "Nome",
				notes: "Note",
				notes_help: [
					"Qualsiasi cosa possa tornarti utile: descrizione, note generali",
					"o qualsiasi altra informazione che pensi possa essere utile.",
				].join(" "),
				price: "Prezzo",
				prices: "Unit./Mag. (SENZA IVA)",
				final_price: "Costo Finito (SENZA IVA)",
				min_quantity: "Quantità minima",
				min_quantity_help: [
					"Se la quantità minima presente in magazzino scende sotto il valore indicato,",
					"il sistema provvederà automaticamente ad inoltrare una notifica via email",
					"per informarti che le giacenze stanno terminando.",
				].join(" "),
				created: "Data di creazione",
				modified: "Ultima modifica",
			},
			buttons: {
				print: "Stampa",
			},
		},
		"sale-channels": {
			name: "Canale di vendita |||| Canali di vendita",
			fields: {
				name: "Nome",
				created: "Data creazione",
				modified: "Data ultima modifica",
			},
		},
		roles: {
			name: "Ruolo |||| Ruoli",
			fields: {
				code: "Codice",
				name: "Nome",
				created: "Data di creazione",
				modified: "Ultima Modifica",
			},
		},
		users: {
			name: "Utente |||| Utenti",
			fields: {
				is_active: "Attivo",
				roles: "Ruoli",
				groups: "Gruppi",
				stores: "Magazzini",
				profile: {
					title: "Profilo",
					name: "Nome",
					surname: "Cognome",
				},
				// Filters
				role: "Ruolo",
				group: "Gruppo",
				active: "Attivo",
				perms: "Permessi",
				q: "Chiave di ricerca",
			},
		},
		customers: {
			name: "Cliente |||| Clienti",
			create: "Crea nuovo cliente",
			fields: {
				id: "id",
				fingerprint: "Fingerprint",
				first_name: "Nome",
				last_name: "Cognome",
				fullname: "Nome completo",
				address: "Indirizzo",
				city: "Città",
				province: "Provincia",
				zip_code: "Cap",
				country: "Nazione",
				phone: "Cellulare",
				email: "Email",
				notes: "Note",
				created: "Data di creazione",
				modified: "Data ultima modifica",
				deleted: "Eliminato",
				has_notes: "Note",
			},
		},
		orders: {
			name: "Ordine |||| Ordini",
			print: "Stampa",
			status_update: "Aggiorna stato dell'ordine",
			check_customer_address: "Verifica indirizzo dei clienti",
			check_customer_address_confirm:
				"Sei sicuro di voler verificare l'indirizzo dei clienti degli ordini selezionati?",
			create_shipping_label: "Crea etichette di spedizione",
			create_shipping_label_confirm:
				"Sei sicuro di voler creare le spedizioni e relative etichette per gli ordini selezionati?",
			delete_shipping_label: "Elimina etichette di spedizione",
			delete_shipping_label_confirm:
				"Sei sicuro di voler eliminare le spedizioni e relative etichette associate agli ordini selezionati?",
			send_sms: "Invia SMS",
			sms_sent: "SMS Inviato correttamente",
			sms_not_sent: "Impossibile inviare SMS",
			fields: {
				edit_amounts: "Modifica importi",
				amount_summary: "Riepilogo importi",
				id: "id",
				code: "Codice",
				fullcode: "Codice",
				order_id: "Ordine parente",
				customer_id: "Cliente",
				quantity: "Quantità",
				total_quantity: "Quantità",
				payment_method: "Metodo di pagamento",
				"payment_method.prepaid": "Prepagato",
				"payment_method.cod": "Contrassegno",
				payment_method_fullname: "Metodo di pagamento",
				amount: "Importo",
				amount_payment_method: "Importo metodo di pagamento",
				total_amount_payment_method: "Importo metodo di pagamento",
				amount_shipping: "Importo spedizione",
				total_amount_shipping: "Importo spedizione",
				amount_shipping_fee: "Maggiorazione spedizione",
				amount_gift_box: "Importo confezione regalo",
				total_amount_gift_box: "Importo confezione regalo",
				amount_order: "Importo totale",
				total_amount_order: "Importo totale",
				amount_upsell: "Importo upsell",
				coupon_code: "Codice sconto",
				coupon_amount: "Valore codice sconto",
				status_id: "Stato",
				store_status: "Status remoto",
				gdpr_privacy: "GDPR Privacy",
				gdpr_marketing: "GDPR Marketing",
				stripe_logs: "Stripe logs",
				gif: "Gif",
				gif_giphy: "Gif giphy",
				notes: "Note",
				notes_internal: "Note interne",
				confirmed: "Confermato",
				created: "Data di creazione",
				created_start: "Da",
				created_end: "A",
				modified: "Data ultima modifica",
				deleted: "Eliminato",
				is_upsell: "Ordine upsell",
				is_resell: "Ordine Resell",
				not_is_upsell: "Ordine non upsell",
				has_notes: "Note",
				tags: "Tags",
				store_id: "Magazzino",
				tracking_url: "Tracking URL",
				confirm_url: "URL Conferma",
				cancel_url: "URL Cancellazione",
				currency: "Valuta",
				confirmation_token: "Token di Conferma",
				carrier_user_id: "Profilo spedizione",
				method: "Metodo",
				"method.json": "JSON",
				"method.csv": "CSV",
			},
			status: {
				update: "Aggiorna status degli ordini selezionati",
				updated: "Gli status degli ordini sono stati aggiornati correttamente",
				error:
					"Impossibile aggiornare gli ordini selezionati, si prega di riprovare più tardi.",
			},
			carrier_user: {
				update: "Aggiorna profilo spedizione degli ordini selezionati",
				updated:
					"I profili spedizione degli ordini selezionati sono stati aggiornati correttamente",
				error:
					"Impossibile aggiornare gli ordini selezionati, si prega di riprovare più tardi.",
			},
			shipping: {
				created:
					"Le spedizioni e le relative etichette di spedizione degli ordini selezionati sono state create.",
				error:
					"Impossibile modificare le spedizioni per gli ordini selezionati",
				create_label: "Crea spedizioni",
				create_label_confirm:
					"Sei sicuro di voler creare le spedizioni per gli ordini selezionati?",
				deleted:
					"Le spedizioni e le relative etichette di spedizione degli ordini selezionati sono state cancellate.",
				delete_label: "Cancella spedizioni",
				delete_label_confirm:
					"Sei sicuro di voler cancellare le spedizioni per gli ordini selezionati?",
			},
		},
		order_items: {
			name: "Prodotto |||| Prodotti",
			fields: {
				order_id: "Ordine",
				name: "Nome",
				metadata: "Descrizione",
				quantity: "Quantità",
				amount: "Importo",
			},
		},
		orders_statuses: {
			name: "Storico status |||| Storico status",
			fields: {
				id: "id",
				order_id: "Ordine",
				status_id: "Stato",
				created: "Data di creazione",
			},
		},
		shipping_trackings: {
			name: "shipping_tracking |||| shipping_trackings",
			fields: {
				id: "id",
				shipping_id: "shipping_id",
				date: "date",
				city: "city",
				status: "status",
				note: "note",
			},
		},
		shippings: {
			name: "Spedizione |||| Spedizioni",
			print: "Stampa etichette",
			export: "Esporta CSV",
			shipping_transmitted: "Spedizioni trasmesse",
			shipping_not_transmitted: "Errore: Impossibile trasmettere spedizioni",
			transmit_shipping: "Trasmetti spedizioni",
			transmit_shipping_info:
				"Sei sicuro di voler trasmettere le spedizioni selezionate?",
			fields: {
				id: "id",
				carrier_user_id: "Corriere",
				order_id: "Ordine",
				store_id: "Negozio",
				marketplace_id: "Marketplace",
				tracking: "Tracking",
				tracking_url: "Tracking URL",
				pdf: "Etichetta",
				status: "Status",
				logs: "Logs",
				created: "Data di creazione",
				created_start: "Da",
				created_end: "A",
				modified: "Data ultima modifica",
				transmitted: "transmitted",
				label_printed: "Stampata",
				last_track_row: "Ultimo aggiornamento",
				statuses: {
					pending: "In attesa",
					transmitted: "Trasmesso",
					delivering: "In consegna",
					"delivery-first-fault": "Mancata consegna",
					delivered: "Consegnato",
					rejected: "Fallito",
				},
			},
			transmit: {
				success: "Le spedizioni selezionate sono state trasmesse correttamente",
				error: "Impossibile trasmettere le spedizioni selezionate",
			},
		},
		"sms-templates": {
			name: "Template SMS",
			fields: {
				id: "id",
				name: "Nome",
				status_id: "Stato",
				code: "Codice",
				message: "Testo dell'SMS",
				message_excerpt: "Testo dell'SMS",
				delay: "Ritardo",
				created: "Data di creazione",
				modified: "Data ultima modifica",
				deleted: "Eliminato",
			},
		},
		statuses: {
			name: "Status",
			fields: {
				id: "id",
				name: "Nome",
				code: "Codice",
				description: "Descrizione",
				color: "Colore",
				deleted: "Eliminato",
				index: "Ordine",
			},
		},
		tags: {
			name: "Tag |||| Tags",
			fields: {
				id: "id",
				name: "Nome",
				created: "Data di creazione",
				modified: "Data ultima modifica",
				deleted: "Eliminato",
			},
		},
		marketplaces: {
			name: "Marketplace |||| Marketplaces",
			fields: {
				id: "id",
				name: "Nome",
				code: "Codice",
			},
		},
		stores: {
			name: "Magazzino |||| Magazzini",
			fields: {
				id: "id",
				name: "Nome",
				phone: "Numero di telefono",
				code: "Codice",
				host: "Host",
				heading: "Intestazione",
				edit_url: "URL Modifica Ordine",
				send_sms: "Invia SMS Automatici",
				sms_dongle: "Scheda SIM",
				sms_sender: "Mittente personalizzato",
			},
		},
		carriers: {
			name: "Corriere |||| Corrieri",
			fields: {
				id: "id",
				name: "Nome",
				code: "Codice",
			},
		},
		carriers_users: {
			name: "Nome configurazione",
			fields: {
				name: "Nome",
				carrier_id: "Corriere",
				user_id: "Utente",
			},
		},
		"carriers-users": {
			name: "Profilo spedizione |||| Profili spedizione",
			fields: {
				name: "Nome",
				carrier_id: "Corriere",
				user_id: "Utente",
			},
		},
		carriers_users_props: {
			name: "Credenziali",
			fields: {
				key: "Chiave",
				value: "Valore",
				sender_code: "Codice Mittente",
				password: "Password",
				contract_code: "Codice Contratto",
				client_code: "Codice Cliente",
				api_key: "Chiave API",
				gmap_api_key: "Chiave API Google Maps",
				from_name: "Nome mittente",
				from_street_1: "Indirizzo mittente",
				from_company: "Società mittente",
				from_city: "Città mittente",
				from_state: "Stato mittente",
				from_zip: "Codice postale mittente",
				from_country: "Country mittente",
				from_phone: "Recapito telefonico mittente",
				from_email: "Indirizzo email mittente",
				currency: "Valuta",
				carrier_id: "ID Corriere",
				carrier_name: "Nome corriere",
				carrier_service: "Servizio corriere",
			},
		},
	},
};

const messages = merge(italianMessages, standardMessages, appMessages);
messages.resources.recycler = messages.resources.explorer;

export default messages;
