import React from "react";
import {
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from "react-admin";
import SmsInput from "./inputs/SmsInput";

const Form = ({
	create = false,
	dispatch,
	validate,
	errors,
	permissions,
	...props
}) => {
	return (
		<SimpleForm redirect="list" {...props}>
			<TextInput source="name" validate={required()} />
			<TextInput source="logo_url" fullWidth />
			<ReferenceInput
				source="marketplace_id"
				reference="marketplaces"
				validate={required()}
			>
				<SelectInput />
			</ReferenceInput>
			<TextInput source="host" />
			<TextInput source="edit_url" fullWidth />
			<NumberInput source="phone" />
			<TextInput source="heading" fullWidth multiline rows={5} />
			<SmsInput />
			<TextInput source="postback_url" disabled fullWidth />
		</SimpleForm>
	);
};

export default Form;
