import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import React, { Fragment, useState } from "react";
import {
	Button,
	useNotify,
	useQuery,
	useRefresh,
	useTranslate,
	useUnselectAll,
	useUpdateMany,
} from "react-admin";

const AssignCarrierProfileButton = ({ selectedIds, resource }) => {
	const [open, setOpen] = useState(false);
	const [profile, setProfile] = useState("");
	const translate = useTranslate();

	const handleClose = () => {
		setOpen(false);
	};

	const handleClick = () => {
		setOpen(true);
	};

	const handleChange = (e) => setProfile(e.target.value);

	const refresh = useRefresh();
	const notify = useNotify();
	const unselectAll = useUnselectAll();
	const [updateMany, { loading }] = useUpdateMany(
		resource,
		selectedIds,
		{ carrier_user_id: profile },
		{
			onSuccess: () => {
				refresh();
				notify("resources.orders.carrier_user.updated");
				unselectAll("orders");
			},
			onFailure: (error) =>
				notify("resources.orders.carrier_user.error", "warning"),
		}
	);

	const { data: carrierProfiles } = useQuery({
		type: "getList",
		resource: "carriers-users",
		payload: {
			pagination: { page: 1, limit: 200 },
			sort: { field: "name", direction: "ASC" },
		},
	});

	return (
		<Fragment>
			<Button
				label="resources.orders.fields.carrier_user_id"
				onClick={handleClick}
			>
				<LocalShippingIcon />
			</Button>
			<Dialog
				fullWidth
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					{translate("resources.orders.carrier_user.update")}
				</DialogTitle>
				<DialogContent>
					<FormControl
						style={{ minWidth: 200 }}
						margin="dense"
						variant="filled"
						fullWidth
						required
					>
						<InputLabel id="carrier_user-bulk-actions-select-label">
							{translate("resources.orders.fields.carrier_user_id")}
						</InputLabel>
						<Select
							autoFocus
							labelId="carrier_user-bulk-actions-select-label"
							id="carrier_user-bulk-actions-select"
							value={profile}
							onChange={handleChange}
						>
							<MenuItem value="">
								<em>{translate("ra.message.none")}</em>
							</MenuItem>
							{carrierProfiles &&
								carrierProfiles.map((profile) => (
									<MenuItem key={profile.id} value={profile.id}>
										{profile.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="primary"
						disabled={loading}
						label="ra.action.undo"
					/>
					<Button
						onClick={updateMany}
						disabled={loading || !profile}
						color="primary"
						label="ra.action.edit"
						variant="contained"
					>
						<LocalShippingIcon />
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

export default AssignCarrierProfileButton;
