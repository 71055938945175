let appUrl = `//${document.location.host}/`;
let environment = "PRODUCTION";
if (appUrl.endsWith(":3000/")) {
	appUrl = "//inventory.nsgsocialwebsrl.developer/";
	environment = "DEVELOPER";
}
export const APP_URL = appUrl;
export const API_URL = `${APP_URL}api`;
export const PRINT_URL = `${API_URL}/orders/prints`;
export const PRINT_LABEL_URL = `${API_URL}/shippings/prints`;
export const EXPORT_URL = `${API_URL}/shippings/export`;
export const VERSION = "2.0.11";
export const ENVIRONMENT = environment;
