import RemoteErrorsInteceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import { Create, required, SimpleForm, TextInput } from "react-admin";
import { compose } from "recompose";

const TagCreate = ({ dispatch, validate, errors, ...props }) => {
	return (
		<Create {...props}>
			<SimpleForm redirect="list" validate={validate}>
				<RemoteErrorsInteceptor errors={errors} dispatch={dispatch} />
				<TextInput validate={[required()]} source="name" />
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(TagCreate);
