import RemoteErrorsInteceptor, { withErrors } from "ra-remote-validator";
import React from "react";
import {
	Create,
	NumberInput,
	required,
	SimpleForm,
	TextInput,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";
import { compose } from "recompose";

const StatusCreate = ({ dispatch, validate, errors, ...props }) => {
	return (
		<Create {...props}>
			<SimpleForm redirect="list" validate={validate}>
				<RemoteErrorsInteceptor errors={errors} dispatch={dispatch} />
				<TextInput validate={[required()]} source="name" />
				<TextInput validate={[required()]} source="code" />
				<TextInput multiline source="description" />
				<ColorInput source="color" />
				<NumberInput source="index" />
			</SimpleForm>
		</Create>
	);
};
export default compose(withErrors)(StatusCreate);
