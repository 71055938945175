import { StorageIcon } from "../icons";
import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
export default {
  options: {
    group: "admin",
    roles: ["admin"],
  },
  icon: StorageIcon,
  list: List,
  edit: Edit,
  create: Create,
};
