import React from "react";
import {
	AutocompleteInput,
	BooleanInput,
	Filter,
	ReferenceInput,
	SelectInput,
	TextInput,
} from "react-admin";
import { DateTimeInput } from "../components/DatePickers";
import statuses from "./statuses";

const ShippingFilters = (props) => (
	<Filter {...props}>
		<TextInput source="q" label="app.q" alwaysOn />
		<DateTimeInput source="created_start" alwaysOn />
		<DateTimeInput source="created_end" alwaysOn />
		<SelectInput choices={statuses} source="status" alwaysOn />
		<ReferenceInput
			source="store_id"
			reference="stores"
			sort={{ field: "name", order: "ASC" }}
			alwaysOn
		>
			<SelectInput />
		</ReferenceInput>
		<ReferenceInput source="carrier_user_id" reference="carriers-users">
			<AutocompleteInput />
		</ReferenceInput>
		<ReferenceInput source="order_id" reference="orders">
			<AutocompleteInput optionText="code" />
		</ReferenceInput>
		<BooleanInput source="label_printed" />
	</Filter>
);
export default ShippingFilters;
