import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useRef, useState } from "react";
import { Error, Loading, TextInput, useQuery, useTranslate } from "react-admin";
import { useField } from "react-final-form";
import { GENERIC } from "../dataProvider";
import { useEventListener } from "../utils/hooks";
import { insertAt } from "../utils/insertAt";

const TextMessageInputHelper = ({ onSelect, ...props }) => {
	const translate = useTranslate();
	const { data: fields, loading, error } = useQuery({
		type: GENERIC,
		resource: "orders/fields",
		payload: {},
	});

	if (loading) return <Loading />;
	if (error) return <Error />;
	if (!fields) return null;

	return (
		<>
			{fields.map((field, i) => (
				<Chip
					key={i}
					avatar={<Avatar>{field.alias}</Avatar>}
					style={{ marginBottom: "0.5em", marginRight: "0.5em" }}
					label={
						field.name
							? translate(field.name)
							: translate(`resources.${field.resource}.fields.${field.code}`)
					}
					onClick={() => onSelect(field)}
				/>
			))}
		</>
	);
};

const cursorPosition = (el) => {
	if (el.selectionStart !== null && el.selectionStart !== undefined) {
		var position = el.selectionStart;
		return position;
	} else {
		return false;
	}
};

const TextMessageInput = ({ disableHelper = false, ...props }) => {
	const ref = useRef();
	const [mounted, setMount] = useState(false);
	const [cursor, setCursor] = useState(0);
	const [input, setInput] = useState(undefined);
	const {
		input: { onChange, value },
	} = useField(props.source);
	const onSelect = (field) => {
		let cursorBackup = cursor;
		let element = `{${field.code}}`;
		onChange(insertAt(value, element, cursorBackup));
		input.focus();
		let pos = cursorBackup + element.length;
		setCursor(pos);
		setTimeout(() => {
			input.setSelectionRange(pos, pos);
		}, 50);
	};
	const inputContainer = typeof ref.current === undefined ? null : ref.current;
	const updateCursor = () => {
		setCursor(cursorPosition(input));
	};
	useEventListener(input, "keyup", updateCursor);
	useEventListener(input, "click", updateCursor);
	useEventListener(input, "focus", updateCursor);
	useEffect(() => {
		if (!mounted && inputContainer) {
			setInput(inputContainer.querySelector("textarea"));
			setMount(true);
		}
	}, [mounted, inputContainer]);

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={6} offset={6}>
					<div ref={ref}>
						<TextInput {...props} />
						{value && (
							<Typography variant="button" display="block" gutterBottom>
								{value.length}/160 - {parseInt(value.length / 160) + 1} sms
							</Typography>
						)}
					</div>
				</Grid>
				{!disableHelper && (
					<>
						<Grid item xs={6} offset={6}></Grid>
						<Grid item xs={6} offset={6}>
							<TextMessageInputHelper onSelect={onSelect} {...props} />
						</Grid>
						<Grid item xs={6} offset={6}></Grid>
					</>
				)}
			</Grid>
		</>
	);
};

export default TextMessageInput;
