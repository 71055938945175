import { green, purple } from "@material-ui/core/colors";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

export default [
	{
		id: "cod",
		name: "resources.orders.fields.payment_method.cod",
		color: purple[500],
		Icon: LocalShippingIcon,
	},
	{
		id: "prepaid",
		name: "resources.orders.fields.payment_method.prepaid",
		color: green[500],
		Icon: EuroSymbolIcon,
	},
];
