import React from "react";
import { List, Datagrid, TextField, DateField, ChipField } from "react-admin";
import Filters from "./Filters";
import PrintButton from "./PrintButton";

const PrintTransactionList = props => (
  <List
    {...props}
    perPage={10}
    filters={<Filters />}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <ChipField source="size" />
      <DateField source="created" showTime />
      <PrintButton />
    </Datagrid>
  </List>
);
export default PrintTransactionList;
