import { Grid } from "@material-ui/core";
import React from "react";
import {
	ArrayInput,
	NumberInput,
	required,
	SimpleFormIterator,
	TextInput,
	useTranslate,
} from "react-admin";

const Form = ({ index, source, label, ...props }) => (
	<Grid container spacing={1}>
		<Grid item md={6}>
			<TextInput
				source={`order_items.${index}.name`}
				label="resources.order_items.fields.name"
				{...props}
			/>
		</Grid>
		<Grid item md={6}></Grid>
		<Grid item md={6}>
			<TextInput
				source={`order_items.${index}.metadata`}
				label="resources.order_items.fields.metadata"
				multiline
				rows={5}
				{...props}
			/>
		</Grid>
		<Grid item md={6}></Grid>
		<Grid item md={3}>
			<NumberInput
				source={`order_items.${index}.quantity`}
				label="resources.order_items.fields.quantity"
				{...props}
			/>
		</Grid>
		<Grid item md={3}>
			<NumberInput
				source={`order_items.${index}.amount`}
				label="resources.order_items.fields.amount"
				{...props}
			/>
		</Grid>
	</Grid>
);

const OrderItemForm = ({ label, ...props }) => {
	const translate = useTranslate();
	return (
		<Grid container spacing={1}>
			<Grid item md={12}>
				<ArrayInput
					validate={[required()]}
					source="order_items"
					label={translate("resources.order_items.name", 0)}
					{...props}
				>
					<SimpleFormIterator>
						<Form fullWidth {...props} />
					</SimpleFormIterator>
				</ArrayInput>
			</Grid>
		</Grid>
	);
};

export default OrderItemForm;
