import React from "react";
import {
	BooleanField,
	Datagrid,
	DateField,
	EditButton,
	EmailField,
	List,
	TextField,
} from "react-admin";
import Pagination from "../components/Pagination";
import perPage from "../utils/perPage";
import Filters from "./Filters";

const CustomerList = (props) => (
	<React.Fragment>
		<List
			{...props}
			perPage={perPage()}
			filters={<Filters />}
			sort={{ field: "modified", order: "DESC" }}
			pagination={<Pagination />}
		>
			<Datagrid rowClick="edit">
				<TextField source="fullname" style={{ fontWeight: "bold" }} />
				<TextField source="city" />
				<TextField source="phone" />
				<EmailField source="email" />
				<BooleanField source="has_notes" />
				<DateField showTime source="modified" />
				<EditButton />
			</Datagrid>
		</List>
	</React.Fragment>
);
export default CustomerList;
