import { SaleChannelIcon } from "../icons";
import List from "./List";
import Edit from "./Edit";
import Create from "./Create";
export default {
  options: {
    group: "manage",
    roles: ["admin"]
  },
  icon: SaleChannelIcon,
  list: List,
  edit: Edit,
  create: Create
};
