import { fetchUtils } from "react-admin";
import { API_URL } from "./config";
import getHeaders from "./authHeaders";
export const GET_STATS = "stats";

const addDashboardFeature = (requestHandler) => (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  if (resource === "dashboard" && type === GET_STATS) {
    console.info("params:", params);
    const url = `${API_URL}/stats`;
    return fetchJson(url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(params),
    }).then((response) => ({
      data: response.json,
    }));
  }
  return requestHandler(type, resource, params);
};

export default addDashboardFeature;
