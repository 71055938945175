import React from "react";
import Chip from "@material-ui/core/Chip";
const PriceField = ({ record }) => (
  <Chip
    color="primary"
    label={`€ ${record.price} (€ ${(
      record.price * (record.stock ? record.stock.loaded : 0)
    ).toFixed(2)})`}
  />
);

export default PriceField;
