import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  translate
} from "react-admin";
import { compose } from "recompose";
import RemoteErrorsInterceptor, { withErrors } from "ra-remote-validator";
import ProfitField from "./fields/ProfitField";

const ProductEdit = ({ translate, dispatch, validate, errors, ...props }) => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect="list" validate={validate}>
      <RemoteErrorsInterceptor errors={errors} dispatch={dispatch} />
      <ReferenceInput source="store_id" reference="stores" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        source="code"
        helperText={translate("resources.products.fields.code_help")}
      />
      <TextInput source="name" />
      <NumberInput source="price" />
      <NumberInput source="final_price" />
      <NumberInput
        source="min_quantity"
        helperText={translate("resources.products.fields.min_quantity_help")}
      />
      <TextInput
        source="notes"
        multiline
        fullWidth
        helperText={translate("resources.products.fields.notes_help")}
      />

      <ArrayInput resource="products" source="sale_channels">
        <SimpleFormIterator>
          <ReferenceInput
            style={{
              display: "block",
              width: 300,
              marginRight: 8,
              float: "left"
            }}
            source="sale_channel_id"
            reference="sale-channels"
          >
            <SelectInput fullWidth optionText="name" />
          </ReferenceInput>
          <NumberInput
            style={{ display: "block", width: 150, float: "left" }}
            source="price"
          />
          <ProfitField />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);
export default compose(withErrors, translate)(ProductEdit);
