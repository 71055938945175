import React from "react";
import {
  Filter,
  DateInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";

const Filters = props => (
  <Filter {...props}>
    <DateInput source="from" alwaysOn />
    <DateInput source="to" alwaysOn />
    <ReferenceInput source="product_id" reference="products">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);
export default Filters;
