import React from "react";
import {
	CloneButton,
	Datagrid,
	DateField,
	EditButton,
	List,
	ReferenceField,
	TextField,
} from "react-admin";
import ChipColorField from "../components/ChipColorField";
import perPage from "../utils/perPage";
import Filters from "./Filters";

const SmsTemplateList = (props) => (
	<React.Fragment>
		<List {...props} perPage={perPage()} filters={<Filters />}>
			<Datagrid rowClick="edit">
				<TextField source="id" />
				<TextField source="name" style={{ fontWeight: "bold" }} />
				<ReferenceField
					allowEmpty
					label="Status"
					source="status_id"
					reference="statuses"
					perPage={100}
				>
					<ChipColorField source="name" />
				</ReferenceField>
				<DateField showTime source="created" />
				<EditButton />
				<CloneButton />
			</Datagrid>
		</List>
	</React.Fragment>
);
export default SmsTemplateList;
