import React from "react";
import compose from "recompose/compose";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-admin";
import CardIcon from "./CardIcon";
import { Typography, Divider } from "@material-ui/core";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import WarningIcon from "@material-ui/icons/Warning";
import icons from "../icons";
import { get } from "lodash";
import StatusField from "../shippings/StatusField";
const styles = (theme) => ({
  main: {
    flex: "1",
    marginTop: 20,
  },
  titleLink: { textDecoration: "none", color: "inherit" },
  card: {
    padding: "16px 0",
    overflow: "inherit",
    textAlign: "right",
  },
  title: {
    padding: "0 16px",
  },
  value: {
    padding: "0 16px",
    minHeight: 48,
  },
  listItem: {
    paddingBottom: 0,
  },
  listItemText: {
    overflowY: "hidden",
    height: "1.5em",
    fontFamily: "Fira Code, Consolas, Courier New",
  },
  warningBox: {
    textAlign: "center",
  },
  warningIcon: {
    fontSize: 50,
  },
});

const RENDERERS = {
  ShippingStatusField: StatusField,
};

const Table = ({
  icon,
  code,
  color,
  columns = [],
  renderers = {},
  rows = [],
  translate,
  classes,
}) => (
  <div className={classes.main}>
    <CardIcon Icon={icons[icon]} bgColor={color} />
    <Card className={classes.card}>
      <Typography className={classes.title} color="textSecondary">
        {translate(`dashboard.${code}.title`)}
      </Typography>
      <Divider />
      <MuiTable padding="default">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column}>
                {translate(`dashboard.${code}.columns.${column}`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(!rows || rows.length === 0) && (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className={classes.warningBox}
              >
                <WarningIcon className={classes.warningIcon} />
                <Typography variant="body2">
                  {translate("dashboard.no_data")}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {rows.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => {
                let content;
                if (
                  renderers &&
                  renderers[column] &&
                  RENDERERS[renderers[column]]
                ) {
                  const Component = RENDERERS[renderers[column]];
                  content = <Component source={column} record={row} />;
                } else {
                  content = get(row, column);
                }
                return (
                  <TableCell key={`col-${column}-${index}`}>
                    {content}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </Card>
  </div>
);

const enhance = compose(withStyles(styles), translate);

export default enhance(Table);
